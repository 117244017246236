import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ClickOutsideDirective from '@/plugins/ClickOutsideDirective'
import PageScrollDirective from '@/plugins/PageScrollDirective'
import VeeValidatePlugin from '@/plugins/VeeValidatePlugin'
// Import one of the available themes
//theme
import 'element-plus/dist/index.css'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
// handle router permission
import './utils/permission'
import i18n from '@/lang/i18n';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
export const forumApp = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  forumApp.component(key, component)
}
forumApp.use(router)
forumApp.use(store)
forumApp.use(ClickOutsideDirective)
forumApp.use(PageScrollDirective)
forumApp.use(VeeValidatePlugin)
forumApp.use(i18n)
forumApp.use(ElementPlus)

const requireComponent = require.context('./components', true, /App[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(function (fileName) {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || (
    fileName
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  forumApp.component(baseComponentName, baseComponentConfig)
})

forumApp.mount('#app')
