<template>
 Logger Page

  <ul>
    <li v-for="log in logs" :key="log.id" :class="log.level">
      {{ log.message }}
    </li>
  </ul>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      logs: [],
    };
  },
  methods: {},

  created() {},

  mounted() {},
};
</script>

<style></style>
