import { get, post } from '../utils/request'
import { GetTongThu, GetDoanhThuNhaThuoc, GetChiBHYT, getKhoas, getKho , GetInfoDashBoard,GetTkKcbDashBoard,GetTkDtDashBoard,GetTongThuExport,
    GetChiBHYTExport,GetDoanhThuNhaThuocExport,GetTongThuTKExport, GetChiBHYTTKExport,GetTonTKKhoExport,GetTonTKThoiGianExport,GetTKDTKCBExport,GetTKDTKCBNTExport,GetRealTime,
    GetTKLuotKCBExport, GetTKLuotKCBNTExport,
    GetTongThuTK, GetChiBHYTTK, GetTonTKThoiGian, GetTonTKKho, getDT, GetTKLuotKCB, GetTKLuotKCBNT, GetTKDTKCBNT, GetTKDTKCB} from '../api/thongke.js'
const actions = {
    GetTongThu(context, body) {
        return post(GetTongThu.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetDoanhThuNhaThuoc(context, body) {
        return post(GetDoanhThuNhaThuoc.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetChiBHYT(context, body) {
        return post(GetChiBHYT.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    getKhoas(context, body) {
        return get(getKhoas.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    getKho(context, body) {
        return get(getKho.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTongThuTK(context, body) {
        return post(GetTongThuTK.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetChiBHYTTK(context, body) {
        return post(GetChiBHYTTK.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTonTKThoiGian(context, body) {
        return post(GetTonTKThoiGian.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTonTKKho(context, body) {
        return post(GetTonTKKho.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    getDT(context, body) {
        return get(getDT.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKLuotKCB(context, body) {
        return post(GetTKLuotKCB.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKLuotKCBNT(context, body) {
        return post(GetTKLuotKCBNT.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKDTKCBNT(context, body) {
        return post(GetTKDTKCBNT.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKDTKCB(context, body) {
        return post(GetTKDTKCB.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetInfoDashBoard(context, body) {
        return post(GetInfoDashBoard.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTkKcbDashBoard(context, body) {
        return post(GetTkKcbDashBoard.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTkDtDashBoard(context, body) {
        return post(GetTkDtDashBoard.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTongThuExport(context, body) {
        return post(GetTongThuExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetChiBHYTExport(context, body) {
        return post(GetChiBHYTExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetDoanhThuNhaThuocExport(context, body) {
        return post(GetDoanhThuNhaThuocExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTongThuTKExport(context, body) {
        return post(GetTongThuTKExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetChiBHYTTKExport(context, body) {
        return post(GetChiBHYTTKExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTonTKKhoExport(context, body) {
        return post(GetTonTKKhoExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTonTKThoiGianExport(context, body) {
        return post(GetTonTKThoiGianExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKDTKCBExport(context, body) {
        return post(GetTKDTKCBExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKDTKCBNTExport(context, body) {
        return post(GetTKDTKCBNTExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKLuotKCBExport(context, body) {
        return post(GetTKLuotKCBExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetTKLuotKCBNTExport(context, body) {
        return post(GetTKLuotKCBNTExport.url, body).then(res => {
            return res;
        }).catch(e => {

        })
    },
    GetRealTime(context, body) {
        return get(GetRealTime.url).then(res => {
            return res;
        }).catch(e => {

        })
    },
};
export default {
    namespaced: true,
    actions
}