<template>
  <div class="fontTextPag">
    <div class="pt_2 pr-16" v-if="displayLeft == 0">
      <span>
        Hiển thị {{(perPage)}} trên tổng số {{ totalItem }} bản ghi
      </span>

    </div>
    <div style="display: flex;  ">
      <div>
        <a class="pr-16" @click="onClickPreviousPage"> <img class="pt_3" src="../assets/images/DoubleLeft.png" alt=""></a>
        <a class="pr-16" @click="onClickPreviousPage"> <img class="pt_3" src="../assets/images/Left.png" alt=""></a>
      </div>

      <div class="pr-16" v-for="page in pages" :key="page.name">
        <button class="nutbamPage" type="button" :class="{ active: page.isDisabled }" @click="onClickPage(page.name)">
          {{ page.name }}
        </button>
      </div>

      <div>
        <a class="pr-16" @click="onClickNextPage"> <img class="pt_3" src="../assets/images/Right.png"> </a>
        <a class="pr-16" @click="onClickNextPage"> <img class="pt_3" src="../assets/images/DoubleRight.png"> </a>
      </div>
      <select v-model="itemsPerPage" @change="updateItemsPerPage(itemsPerPage)" class="selectSt">
        <option v-for="perPage in totalInOnePage" :key="perPage" :value="perPage">
          {{ perPage }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //  totalPages: 2,
      totalInOnePage: [10, 20, 30, 40, 50, 100],
      total: 6,
      currentPage: 1,
      perPage: 10,
      itemsPerPage: 10,
    };
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 4,
    },
    totalItem: {
      type: Number,
      required: true,
    },
    displayLeft: {
      type: Number,
      default: 0
    },
  },
  methods: {
    updateItemsPerPage(perPage) {
      this.$emit("perpagechanged", perPage);
      this.currentPage = 1;
      this.perPage = perPage;
    },

    onClickPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.$emit("pagechanged", this.currentPage);
      }
    },
    onClickPage(page) {
      this.currentPage = page;
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1; // Thay đổi currentPage trước khi gọi sự kiện pagechanged
        this.$emit("pagechanged", this.currentPage);
      }
    },
  },
  computed: {
    totalPages() {
      var totalpage = Math.ceil(this.totalItem / this.perPage)
      if (totalpage > 0) {
        return totalpage
      } else {
        this.currentPage = 1;
        return 1;
      }
    },

    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i += 1
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      if (this.totalPages == 0) {
        range.push({
          name: 1,
          isDisabled: true
        });
      }
      return range;
    },

    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return Math.max(this.totalPages - this.maxVisibleButtons + 1, 1);
      }

      return Math.max(
        this.currentPage - Math.floor(this.maxVisibleButtons / 2),
        1
      );
    },
  },
  watch: {},
};
</script>

<style>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  color: #6bd9e3;
}

.nutbamPage {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid var(--stroke-2, #F0F2F5);
  background: #F0F2F5;
  color: #6D767E;
}

.nutbamPage.active {
  background: #166987;
  border: 1px solid var(--stroke-2, #166987);
  color: #FFF;
}

.selectSt {
  width: 50px;
  height: 30px;
  border-radius: 4px;
  border-color: #D9D9D9;
    color: #6D767E;
}

.fontTextPag {
  color: #6D767E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 6px 0px 6px 0px;
}
.pt_2{
  padding-top: 0.2%;
}
.pt_3{
  padding-top: 9%;
}
.selectSt:focus-visible{
  border-color: #D9D9D9;
}

</style>
