<template>
  <div class="v-container" id="page-login">
    <div class="v-container__inner">
      <div class="frm-login frm-login-wrap">
        <div class="frm-login__main grid-form-login">
          <div class="titleLogin">HỆ THỐNG GIÁM SÁT SỐ LIỆU BỆNH VIỆN</div>
          <div class="frm-login__form">
            <VeeForm class="formLogin" @submit="signInButton">
              <div class="form-group">
                <AppFormField :label="$t('user_name')" name="username" type="username" v-model="form.username"
                  rules="required" :placeholder="$t('user_name')" id="username" :maxlength="30"></AppFormField>
              </div>
              <div class="form-group">
                <AppFormField :label="$t('pass_word')" name="password" type="password" v-model="form.password"
                  rules="required" :placeholder="$t('pass_word')" :maxlength="30" />
              </div>
              <div class="row">
                <div class="col-12">
                  <button type="submit" class="btn-blue btn-block">
                    Đăng nhập
                  </button>
                </div>
              </div>
            </VeeForm>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
import { LOADING } from "../../src/utils/constant";
export default {
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      login: "",
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions('SignInApi', ['signIn']),
    ...mapMutations({
      showLoading: LOADING
    }),

    signInButton() {
      this.showLoading(true);
      this.signIn(this.form).then((res) => {
        window.localStorage.setItem('x-token-nhis', res.item.token)
        window.localStorage.setItem('username', res.item.useR_ID)
        window.localStorage.setItem('cskcb', res.item.cskcb)
        this.successRedirect();
      }).catch((e) => {

      }).finally(() => {
        this.showLoading(false);
      });
    },
    successRedirect() {
      const redirectTo = this.$route.query.redirectTo || {
        name: "Home"
      };
      this.$router.push(redirectTo);
    },

  },
};
</script>

<style>
#page-login {
  background-image: url('../assets/images/Layer.png');
  background-size: cover;
  background-position: center;
  height: 100%;
}

.v-container .v-container__inner {
  padding: 0;
  display: flex;
  height: 100%;
}

.frm-login {
  width: 40%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.frm-login--inner {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}

.frm-login__language {
  margin-left: 5px;
  border: none;
}

.frm-login__form {
  margin: 0 auto;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frm-login__form h1 {
  margin-top: 0;
}

.frm-login__form form {
  flex: 1;
}

.form-group {
  padding: 0px 0 15px;
  line-height: 20px;
  position: relative;
  display: block;
}

.push-top {
  padding-top: 40px;
}


/* .text-center {
  font-size: 20px;
  font-weight: 600;
  color: white;
} */
.img_icon {
  position: absolute;
  padding: 12px;
  right: 0;
  transform: translateY(-50%);
  top: 43%;
}

.grid-form-login {
  flex: 1;
  display: flex;
  justify-content: center;
}

.slt-language {
  border: none;
  color: #152647;
  font-weight: 600;
  margin-right: 50px;
  margin-top: 20px;
  font-size: 18px;
}

.card-header:first-child {
  background-color: #1275ca;
}

.formLogin {
  padding: 16px;
  border-radius: 8px;
  background: var(--base-white, #FFF);
}

.titleLogin {
  color: var(--base-white, #FFF);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  position: fixed;
  margin-top: 10%;
  margin-left: 3%;
}

.formLabel {
  color: var(--text-primary, #020B27);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 3px;
}

@media screen and (max-width: 997px) {
  .frm-login {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .frm-login {
    width: 96%;
    max-width: unset;
    border-radius: 50px;
    margin: 0 auto;
    padding: 0 15px;
  }
}
</style>
