export const GetTongThu = {
  url: '/thongke/GetTongThu',
};
export const GetDoanhThuNhaThuoc = {
  url: '/thongke/GetDoanhThuNhaThuoc',
};
export const GetChiBHYT = {
  url: '/thongke/GetChiBHYT',
};
export const getKhoas = {
  url: '/thongke/getKhoas',
};
export const getKho = {
  url: '/thongke/getKho',
};
export const GetTongThuTK = {
  url: '/thongke/GetTongThuTK',
};
export const GetChiBHYTTK = {
  url: '/thongke/GetChiBHYTTK',
};
export const GetTonTKThoiGian = {
  url: '/thongke/GetTonTKThoiGian',
};
export const GetTonTKKho = {
  url: '/thongke/GetTonTKKho',
};
export const getDT = {
  url: '/thongke/getDT',
};
export const GetTKLuotKCB = {
  url: '/thongke/GetTKLuotKCB',
};
export const GetTKLuotKCBNT = {
  url: '/thongke/GetTKLuotKCBNT',
};
export const GetTKDTKCBNT = {
  url: '/thongke/GetTKDTKCBNT',
};
export const GetTKDTKCB = {
  url: '/thongke/GetTKDTKCB',
};
export const GetInfoDashBoard = {
  url: '/thongke/GetInfoDashBoard',
};
export const GetTkKcbDashBoard = {
  url: '/thongke/GetTkKcbDashBoard',
};
export const GetTkDtDashBoard = {
  url: '/thongke/GetTkDtDashBoard',
};
export const GetTongThuExport = {
  url: '/thongke/GetTongThuExport',
};
export const GetChiBHYTExport = {
  url: '/thongke/GetChiBHYTExport',
};
export const GetDoanhThuNhaThuocExport = {
  url: '/thongke/GetDoanhThuNhaThuocExport',
};
export const GetTongThuTKExport = {
  url: '/thongke/GetTongThuTKExport',
};
export const GetChiBHYTTKExport = {
  url: '/thongke/GetChiBHYTTKExport',
};
export const GetTonTKKhoExport = {
  url: '/thongke/GetTonTKKhoExport',
};
export const GetTonTKThoiGianExport = {
  url: '/thongke/GetTonTKThoiGianExport',
};
export const GetTKDTKCBExport = {
  url: '/thongke/GetTKDTKCBExport',
};
export const GetTKDTKCBNTExport = {
  url: '/thongke/GetTKDTKCBNTExport',
};
export const GetTKLuotKCBExport = {
  url: '/thongke/GetTKLuotKCBExport',
};
export const GetTKLuotKCBNTExport = {
  url: '/thongke/GetTKLuotKCBNTExport',
};
export const GetRealTime = {
  url: '/thongke/GetRealTime',
};