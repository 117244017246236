<template>
  <div class="pad">
    <div class="header-control row">
    </div>
    <div class="content-wrapper row">
      <div class="col-8">
        <div class="table-container">
          <table class="table table-hover">
            <thead>
              <tr class="background">
                <th class="left-header columnSTT">
                  <span class="fontsize_13">STT</span>
                </th>
                <th class="left-header column18">
                  <span class="fontsize_13">Tên bệnh nhân</span>
                </th>
                <th class="left-header column18">
                  <span class="fontsize_13">Phòng</span>
                </th>
                <th class="left-header column18">
                  <span class="fontsize_13">Thời gian</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-if="itemTable.length > 0" v-for="(resource, index) in itemTable" :key="resource._id">
                <td class="text-left">{{ resource.ordeR_NO }}</td>
                <td class="text-left">{{ resource.hO_TEN }}</td>
                <td class="text-left">{{ resource.mA_PHONG_KHAM }}</td>
                <td class="text-left">{{ resource.ngaY_KHAM }}</td>
              </tr>
              <tr v-if="itemTable.length == 0">
                <td colspan="6" class="text-center">
                  Không có dữ liệu
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
      <div class="col-4">
        <div class="table-container">
          <table class="table table-hover">
            <thead>
              <tr class="background">
                <th class="left-header column18">
                  <span class="fontsize_13 ">Phòng khám</span>
                </th>
                <th class="text-right column18">
                  <span class="fontsize_13">Chờ khám/Đã khám</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-if="itemTable1.length > 0" v-for="(resource, index) in itemTable1" :key="resource._id">
                <td class="text-left">{{ resource.teN_DV }}</td>
                <td class="text-right">{{ resource.danG_KHAM }}/ {{ resource.dA_KHAM }}</td>
              </tr>
              <tr v-if="itemTable1.length == 0">
                <td colspan="6" class="text-center">
                  Không có dữ liệu
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapActions } from "vuex";
import PageTT from "../../components/PageTT";
import Moment from 'moment';
import { LOADING } from "../../utils/constant";
import { ElNotification } from 'element-plus';
import { ExcelMixin } from "../../mixins/exportExcel"
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
export default {
  components: { PageTT },
  mixins: [ExcelMixin],
  data() {
    return {

      itemTable: [],
      itemTable1: [],
      total: 0,
      totalPages: 0,
      perPage: 10,
      totalItem: null,
    };
  },

  created() {
    // Tạo kết nối SignalR đến máy chủ
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_BASE_URL + 'dataHub') // Địa chỉ máy chủ SignalR
      .build();

    // Kết nối tới máy chủ SignalR
    connection.start()
      .then(() => {
      })
      .catch(error => {
        console.error('Failed to connect to SignalR server:', error);
      });

    // Đăng ký các sự kiện
    connection.on('DataChanged', () => {
      this.get();
    });
    this.get();
  },

  methods: {
    ...mapActions('thongke', ['GetRealTime']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    get() {
      this.showLoading(true);
      this.GetRealTime().then((res) => {
        this.itemTable = res.item.item;
        this.itemTable1 = res.item.item1;
      }).finally(this.showLoading(false))

    },
    changePage(page) {
      this.paramTongThu.index = page;
      this.get();
    },
    perPageSTT(perPage) {
      this.paramTongThu.pagesize = perPage;
      this.paramTongThu.index = 1;
      this.get();
    },
    gotoPage(page) {
      this.paramTongThu.index = page;
      // this.getPermissions(this.paramTongThu.index);
    },
    nextPage() {
      if (this.paramTongThu.index < this.totalPages) {
        this.paramTongThu.index++;
        // this.getPermissions(this.paramTongThu.index);
      }
    },
    handlePageChange(event) {
      const selectedPage = event.page; // Lấy số trang được chọn
      this.paramTongThu.index = selectedPage;
    },
    handleValueChange(event) {
      const selectedRowsPerPage = event.value;
      this.paramTongThu.pagesize = selectedRowsPerPage;
      this.paramTongThu.index = 1;

    },

    previousPage() {
      if (this.paramTongThu.index > 1) {
        this.paramTongThu.index--;
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${day}-${month}-${year}`;
    },
   

  },
  computed: {
  },
  mounted() {
  
  }
};
</script>

<style scoped>
.pad {
  padding: 8px 16px;
  height: 100vh;
  overflow-y: auto;
}

.form-perpage1 {
  color: black;
  font-weight: 400;
}

.form-perpage2 {
  color: black;
  font-weight: 300;
}

.content-wrapper {
  background-color: var(--base-background, #F5F8FA);
  /* padding: 20px; */
  border-radius: 10px;
  margin-top: 20px;
}

.status-cell {
  padding: 4px;
  border-radius: 4px;
}

.select-input {
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px;
  gap: 10px;
  border: 1px solid #727981;
}

.header-control {
  padding-top: 0;
  justify-content: space-between;
  align-items: center;
}

.header-control-left {
  display: flex;
}

.header-control-rigth {
  float: right;
}

.input-control {
  max-width: 222px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-bottom: 10px;
}
.table-container{
  height: 800px;
}
.el-select {
  width: 100%;
}
.spanRealTime{
  display: flex;
  justify-items: flex-end;
}
</style>
