import router from '../router'
// import { getToken } from '../utils/auth' // get token from cookie

const whiteList = ['/'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  if (window.localStorage.getItem('x-token-nhis')) {
    /* has token */
    if (to.path === '/') {
      next({ path: '/TongThu' })
    } else {
      next()
    }
  } else {
    /* has no token */
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/')
    }
  }
  // next();
})

router.afterEach(() => {
})
