import { setLocale } from '@vee-validate/i18n';

const SET_LANG = "SET_LANG";

const state = {
  myVariable: '111111'
};

const mutations = {
  setlang(state, value) {
    state.myVariable = value;
  }
};
const getters = {
  getlang(state) {
    return state.myVariable;
  }
};
const actions = {
  updatelang({ commit }, value) {
    commit('setlang', value);
  }
};

const storeLang = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

export default storeLang;
