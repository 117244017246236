import request from '../utils/request'

export function login(payload) {
  return request({
    url: '/user/login',
    method: 'post',
    data: payload
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

export function getlanguagelogin(payload) {
  return request({
    url: '/localization?form_id=' + payload,
    method: 'get'
  })
}
