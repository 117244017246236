import {utils, writeFile} from 'xlsx'

export const ExcelMixin = {
  methods: {
    fillDataToExcel(data, headers, fileName) {
      if (!data || !data.length) {
        console.error("Dữ liệu trống. Không thể tạo tệp Excel.");
        return;
      }
    
      const workbook = utils.book_new();
      const worksheet = utils.aoa_to_sheet([headers]);
    
      const dataArray = data.map(obj => Object.values(obj));
      const dataRange = utils.sheet_add_aoa(worksheet, [headers, ...dataArray]);
    
      const range = utils.decode_range(worksheet["!ref"]);
      console.log(worksheet["!cols"]);
      const borderStyle = {
        style: 'thin',
        color: { rgb: '000000' }
      };
    
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = utils.encode_cell({ r: rowNum, c: colNum });
          const cell = worksheet[cellAddress];
    
          if (cell) {
            cell.s = { border: borderStyle };
          }
        }
      }
    
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
      writeFile(workbook, fileName);
    },
    saveAsExcel(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};