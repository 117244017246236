<template>
  <div class="page-container" >
    <the-navbar v-if="!isSignIn" :isRealTime="isRealTime" />
    <div v-if="!isSignIn" class="home-section" :class="showLoading ? 'showloading' : ''" >
      <router-view v-show="showPage"
        :key="`${$route.path}${JSON.stringify($route.query)}`" />
    </div>
    <div v-if="isSignIn" class="home-section pageLogin" :class="showLoading ? 'showloading' : ''" >
      <router-view v-show="showPage"
        :key="`${$route.path}${JSON.stringify($route.query)}`" />
    </div>
    <div v-show="showLoading" class="loading">
    <Loading />
  </div>
  </div>
</template>

<script>
import TheNavbar from "../src/components/TheNavBar.vue";
import NavBarFunctionList from "../src/components/NavBarFunctionList.vue";
import Loading from "../src/components/Loading";
import { mapActions, mapState } from "vuex";
import NProgress from "nprogress";
export default {
  name: "App",
  components: { TheNavbar, NavBarFunctionList, Loading },
  data() {
    return {
      showPage: true,
      showHeader: false
    };
  },
  computed: {
    ...mapState({
      showLoading: state => state.showLoading
    }),
    isSignIn() {
      return this.$route.name === "SignIn";
    },
    isRealTime() {
      return this.$route.name === "ThongKeKCBRealTime";
    }
  },
  methods: {}
};
</script>

<style>
@import "../src/assets/css/style.css";
@import "~nprogress/nprogress.css";

#nprogress .bar {
  background: #ffffff !important;
}

.showloading{
    opacity: 40%;
}
.page-container {
  min-height: 100%;
  margin-bottom: -60px;
  padding-bottom: 60px;
  overflow: hidden;
}

.footer {
  height: 60px;
  /* Kích thước footer */
}


.pageLogin {
  left: 0px !important;
  width: 100% !important;
}
.home-section {
  position: relative;
  background:  var(--base-background, #F5F8FA);
  height: 100vh;
  left: 230px;
  width: calc(100% - 230px);
  transition: all 0.5s ease;
}
.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

.header.close~.home-section {
  left: 60px;
  width: calc(100% - 60px);
}
</style>
