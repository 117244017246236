<template>
  <div class="pad">
    <div class="row">
      <div class="col-4">
        <div class="boxHeader">
          <p class="title_boxHeader InterM">Doanh thu</p>
          <label class="number_boxHeader InterB">{{ parseFloat(DTToDay).toLocaleString() }}</label>
          <div class="bottomBox">
            <lable class="bottom_boxHeader InterM">{{ TyleDT < 0 ? 'Giảm' : 'Tăng' }} <span
                :class="TyleDT < 0 ? 'redSpan' : 'greenSpan'">{{ Math.abs(TyleDT) }}%</span> so với ngày hôm qua</lable>
            <img
              :src="require(TyleDT < 0 ? '../assets/images/arrow-trend-down.svg' : '../assets/images/arrow-trend-up.svg')"
              alt="">
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="boxHeader">
          <p class="title_boxHeader InterM">Lượt khám chữa bệnh</p>
          <label class="number_boxHeader InterB">{{ parseFloat(KcbToDay).toLocaleString() }}</label>
          <div class="bottomBox">
            <lable class="bottom_boxHeader InterM">{{ KcbYes < 0 ? 'Giảm' : 'Tăng' }} <span
                :class="KcbYes < 0 ? 'redSpan' : 'greenSpan'">{{ Math.abs(KcbYes) }} lượt</span> so với ngày hôm qua</lable>
            <img
              :src="require(KcbYes < 0 ? '../assets/images/arrow-trend-down.svg' : '../assets/images/arrow-trend-up.svg')"
              alt="">
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="boxHeader">
          <p class="title_boxHeader InterM">Tổng chi BHYT</p>
          <label class="number_boxHeader InterB">{{ parseFloat(TongChiBHToDay).toLocaleString() }}</label>
          <div class="bottomBox">
            <lable class="bottom_boxHeader InterM">{{ TyleBH < 0 ? 'Giảm' : 'Tăng' }} <span
                :class="TyleBH < 0 ? 'redSpan' : 'greenSpan'">{{ Math.abs(TyleBH) }}%</span> so với ngày hôm qua</lable>
            <img
              :src="require(TyleBH < 0 ? '../assets/images/arrow-trend-down.svg' : '../assets/images/arrow-trend-up.svg')"
              alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper row ">
      <div class="col-12 mt-20">
        <div class="tableLeft">
          <label class="label-top">Thống kê số lượt Khám chữa bệnh</label>
          <div class="label-right">
            <label>ĐVT: Lượt</label>
            <img class="imgDownLoad" @click="printChart()" src="../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvas"></canvas>
        </div>
      </div>
      <div class="col-12 mt-20">
        <div class="tableLeft">
          <label class="label-top">Thống kê doanh thu</label>
          <div class="label-right">
            <label>ĐVT: Nghìn đồng</label>
            <img class="imgDownLoad" @click="printChartTK()" src="../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvasTK"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { LOADING } from "../utils/constant";
import Chart from 'chart.js/auto';
import { bottom } from "@popperjs/core";
import { ExcelMixin } from "../mixins/exportExcel"
import { ExportChart } from "../mixins/exportChartToImg";
import Moment from 'moment';
export default {
  mixins: [ExcelMixin, ExportChart],
  data() {
    return {
      itemTable: [],
      itemTableTK: [],
      currentPage: 1,
      total: 0,
      totalPages: 20,
      showPopup: false,
      selectedUser: null,
      itemResource: [],
      perPage: 10,
      perPageOptions: [10, 20, 50],

      DTToDay: 0,
      TyleDT: 0,
      KcbToDay: 0,
      KcbYes: 0,
      TongChiBHToDay: 0,
      TyleBH: 0,

      lableChartTT: [],
      dataSetTT: [{
        label: 'Đúng tuyến',
        backgroundColor: "#164E63",
        data: [],
      }, {
        label: 'Cấp cứu',
        backgroundColor: "#0E7490",
        data: [],
      }, {
        label: 'Trái tuyến',
        backgroundColor: "#06B6D4",
        data: [],
      }, {
        label: 'Lĩnh thuốc theo giấy hẹn',
        backgroundColor: "#67E8F9",
        data: [],
      },
      {
        label: 'Khám bệnh, chữa bệnh dịch vụ',
        backgroundColor: "#CFFAFE",
        data: [],
      }
      ],
      chart: null,

      lableChartTK: [],
      dataSetTK: [{
        label: 'Đúng tuyến',
        backgroundColor: "#164E63",
        data: [],
      }, {
        label: 'Cấp cứu',
        backgroundColor: "#0E7490",
        data: [],
      }, {
        label: 'Trái tuyến',
        backgroundColor: "#06B6D4",
        data: [],
      }, {
        label: 'Lĩnh thuốc theo giấy hẹn',
        backgroundColor: "#67E8F9",
        data: [],
      },
      {
        label: 'Khám bệnh, chữa bệnh dịch vụ',
        backgroundColor: "#CFFAFE",
        data: [],
      }
      ],
      chartTK: null
    };
  },
  created() {
    //this.renderChart()
  },
  mounted() {
    this.get();
    //this.exportToExcel();
  },
  methods: {
    ...mapActions('thongke', ['GetInfoDashBoard', 'GetTkKcbDashBoard', 'GetTkDtDashBoard']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    exportToExcel() {
      const header = ['Tên', 'Tuổi', 'Địa chỉ'];
      const filename = 'data.xlsx';
      const data = [
        ['Alice', 25, '123 ABC Street'],
        ['Bob', 30, '456 XYZ Street'],
        ['Charlie', 35, '789 QWE Street'],
      ];

      this.fillDataToExcel( data, header, filename);
    },
    get() {
      this.showLoading(true);
      this.GetInfoDashBoard().then((res) => {
        this.DTToDay = res.item.item.doanhthutoday;
        this.TyleDT = res.item.item.tylE_DT;
        this.KcbToDay = res.item.item.luotkcbtoday;
        this.KcbYes = res.item.item.tylE_KCB;
        this.TongChiBHToDay = res.item.item.tongchibhtoday;
        this.TyleBH = res.item.item.tylE_TONG_CHI;
      }).finally(() => {
      });
      this.GetTkKcbDashBoard().then((res) => {
        this.itemTable = res.item.lstItem;
        this.lableChartTT = [...new Map(res.item.lstItem.map((m) => [m.mA_KHOA, m.mA_KHOA])).values()];

      }).then(() => {
        this.dataSetTT.forEach((el) => {
          el.data = Array(this.lableChartTT.length).fill(0);
        })
      }).finally(() => {
        this.itemTable.forEach(element => {
          if (element.dt == '1') {
            this.dataSetTT[0].data[this.lableChartTT.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '2') {
            this.dataSetTT[1].data[this.lableChartTT.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '3') {
            this.dataSetTT[2].data[this.lableChartTT.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '7') {
            this.dataSetTT[3].data[this.lableChartTT.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '9') {
            this.dataSetTT[4].data[this.lableChartTT.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          }
        });
        this.renderChart();
      });


      this.GetTkDtDashBoard().then((res) => {
        this.itemTableTK = res.item.lstItem;
        this.lableChartTK = [...new Map(res.item.lstItem.map((m) => [m.mA_KHOA, m.mA_KHOA])).values()];

      }).then(() => {
        this.dataSetTK.forEach((el) => {
          el.data = Array(this.lableChartTK.length).fill(0);
        })
      }).finally(() => {

        this.itemTableTK.forEach(element => {
          if (element.dt == '1') {
            this.dataSetTK[0].data[this.lableChartTK.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '2') {
            this.dataSetTK[1].data[this.lableChartTK.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '3') {
            this.dataSetTK[2].data[this.lableChartTK.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '7') {
            this.dataSetTK[3].data[this.lableChartTK.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          } else if (element.dt == '9') {
            this.dataSetTK[4].data[this.lableChartTK.indexOf(element.mA_KHOA)] += parseInt(element.lk);
          }
        })
        this.showLoading(false);
        this.renderChartTK();
      });
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.lableChartTT,
          datasets: this.dataSetTT,
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
              position: bottom

            },
          }

        },

      });
    },
    renderChartTK() {
      const ctx = this.$refs.chartCanvasTK.getContext('2d');
      if (this.chartTK) {
        this.chartTK.destroy();
      }
      this.chartTK = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.lableChartTK,
          datasets: this.dataSetTK,
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
              position: bottom

            },
          }

        },

      });
    },
    printChart() {
      const canvas = this.$refs.chartCanvas;
      const filename = 'luotkcb_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
    },
    printChartTK() {
      const canvas = this.$refs.chartCanvasTK;
      const filename = 'doanhthu_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
    },
  },
};
</script>

<style scoped>
.pad {
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 40px;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
}

.boxHeader {
  background-color: #FFF;
  padding: 16px;
  display: grid;
  border-radius: 8px;
}

.title_boxHeader {
  color: var(--text-primary, #020B27);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.number_boxHeader {
  color: var(--text-primary, #020B27);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  /* 112.5% */
}

.bottom_boxHeader {
  color: var(--pallate-gray-gray-400, #9CA3AF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-top: 4px;
}

.bottom_boxHeader span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.greenSpan {
  color: var(--pallate-green-green-600, #16A34A);
}

.redSpan {
  color: var(--pallate-red-red-600, #DC2626);
}

.bottomBox {
  display: flex;
  justify-content: space-between;
}

.tableLeft {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 56px 24px 0px 24px;
}

.chart_Canvas {
  min-height: 280px !important;
  width: auto !important;
  max-height: 280px !important;
}

.label-top {
  position: absolute;
  left: 24px;
  top: 16px;
  color: var(--text-primary, #020B27);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-family: 'InterM';
}

.label-right {
  position: absolute;
  right: 24px;
  top: 16px;
  color: var(--pallate-gray-gray-400, #9CA3AF);
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  font-family: 'InterM';
}
.label-right label {
  margin-right: 16px;
}
</style>
