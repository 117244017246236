<template>
  <header class="header" :class="!isNavOpen ? 'close' : ''" id="header">
    <div v-bind:class="{ closeMenu: !isNavOpen }"></div>
    <div class="flexbox" style="
        color: #0E7490;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 100%;
      ">
      <div class="titleWeb" v-bind:class="{ openMenu: isNavOpen }">
        HỆ THỐNG GIÁM SÁT SỐ LIỆU {{ nameHospital }}
      </div>
      <div style="
          color: #0E7490;
          width: 33%;
          justify-content: right;
          align-items: center;
        " class="flexboxRow">
        <a v-if="!isRealTime"> <img class="logo1" src="../assets/images/icon_bell.svg" /></a>

        <div class="flexboxColum">
          <div v-if="!isRealTime">{{ username }} </div>
          <div v-if="isRealTime">{{ currentTime }}</div>
        </div>
        <div v-if="!isRealTime" @click="ShowBox()" class="avatar-menu" id="avatar-menu">
          <img class="logo" src="../assets/images/icon_ava.png" />
          <ul id="avatar-dropdownmenu" :class="showbox ? 'showBox' : ''">
            <li @click="SignOut"><a><span class="icon icon-user"></span> Đăng xuất</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div @mouseover="toggleNavHover" class="sidebar" :class="!isNavOpen ? 'close' : ''">
      <div class="iconMenu" @click="toggleNav">
        <div class="logo-details">
          <img class="logoBv" src="../assets/images/logoMenu.png" />
          <span class="logo_name">
            <div class="titleWeb titleNhis InterB"></div>
            <img class="logo_thu" src="../assets/images/icon_thuMenu.png" />
          </span>
        </div>
      </div>

      <ul class="nav-links">
        <li :class="[{ 'open': menu.isOpen }, { 'active': menu.isActive }]" v-for="(menu, index) in menus" :key="index">
          <div class="rowParent" @click="rotateIcon(index)">
            <img class="iconLeft" :src="menu.isActive ? menu.imgLActive : menu.imgL">
            <div class="link-name">
              <router-link class="nameUl" v-if="menu.subMenu.length == 0" :to="menu.path">{{ menu.linkName
              }}</router-link>
              <p class="nameUl" v-if="menu.subMenu.length > 0">{{ menu.linkName }}</p>
            </div>
            <img class="iconRight" v-if="menu.subMenu.length > 0" :src="menu.isActive ? menu.imgRActive : menu.imgR" />
          </div>
          <ul class="sub-menu" v-if="menu.subMenu.length > 0 && menu.isOpen && isNavOpen">
            <li v-for="(subMenu, subIndex) in menu.subMenu" :class="{ 'activeSub': subMenu.isActive }" :key="subIndex">
              <router-link :to="subMenu.path">{{ subMenu.linkName }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </header>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Thay Đổi Mật Khẩu</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div>
              <label for="PassWord" class="form-label">Nhập mật khẩu cũ </label>
              <input v-model="formData.oldPassword" type="password" id="PassWord" class="form-control" required />
            </div>

            <div>
              <label for="PassWord" class="form-label">Nhập mật khẩu mới</label>
              <input v-model="formData.newPassword" type="password" id="PassWord" class="form-control" required />
            </div>

            <div>
              <label for="PassWord" class="form-label">Nhập lại mật khẩu
                mới</label>
              <input v-model="formData.reNewPassword" type="password" id="PassWord" class="form-control" required />
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import Moment from 'moment';
export default {
  props: {
    isRealTime: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        newPassword: "",
        oldPassword: "",
        reNewPassword: "",
      },
      isDropdownOpen: false,
      menuCheck: {
        employee: false,
        checkin: false,
        group: false,
        company: false,
        department: false,
        shift: false,
        role: false,
        permission: false,
        report: false,
        timesheet: false,
        user: false,
      },
      isNavOpen: false,
      showbox: false,
      nameHospital: '',
      currentTime: '',
      menus: [{
        linkName: "Trang chủ",
        imgL: require('../assets/images/menu_Home.svg'),
        imgR: require('../assets/images/iconRight.svg'),
        imgLActive: require('../assets/images/menu_HomeActive.svg'),
        imgRActive: require('../assets/images/iconRightActive.svg'),
        isActive: false,
        isOpen: false,
        path: "/Home",
        subMenu: []
      },
      {
        linkName: "Khám chữa bệnh",
        imgL: require('../assets/images/KCB.svg'),
        imgR: require('../assets/images/iconRight.svg'),
        imgLActive: require('../assets/images/KCBActive.svg'),
        imgRActive: require('../assets/images/iconRightActive.svg'),
        isActive: false,
        isOpen: false,
        path: "/",
        subMenu: [{
          linkName: "Theo dõi KCB trong ngày",
          path: "/ThongKeKCBRealTime",
          isActive: false,
        },
        {
          linkName: "Thống kê theo khoa",
          path: "/ThongKeTheoKhoa",
          isActive: false,
        },
        {
          linkName: "Thống kê theo đối tượng",
          path: "/ThongKeTheoDT",
          isActive: false,
        },
        ]
      },
      {
        linkName: "Doanh thu",
        imgL: require('../assets/images/DoanhThu.svg'),
        imgR: require('../assets/images/iconRight.svg'),
        imgLActive: require('../assets/images/DoanhThuActive.svg'),
        imgRActive: require('../assets/images/iconRightActive.svg'),
        isActive: false,
        path: "/",
        isOpen: false,
        subMenu: [{
          linkName: "Tổng thu",
          path: "/TongThu",
          isActive: false,
        },
        {
          linkName: "Doanh thu nhà thuốc",
          path: "/DoanhSoNhaThuoc",
          isActive: false,
        },
        {
          linkName: "Chi BHYT",
          path: "/GetChiBHYT",
          isActive: false,
        },
        {
          linkName: "Thống kê doanh thu",
          path: "/thongkedoanhthu",
          isActive: false,
        }
        ]
      },
      {
        linkName: "Nhập - Xuất - Tồn",
        imgL: require('../assets/images/NhapXuatTon.svg'),
        imgR: require('../assets/images/iconRight.svg'),
        imgLActive: require('../assets/images/NhapXuatTonActive.svg'),
        imgRActive: require('../assets/images/iconRightActive.svg'),
        isActive: false,
        isOpen: false,
        path: "/TKXuatNhapTon",
        subMenu: []
      }
      ]
    };
  },
  components: {

  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
  },
  methods: {
    ShowBox() {
      this.showbox = !this.showbox;
    },
    updateTime() {
      this.currentTime = Moment().format('DD/MM/YYYY HH:mm:ss');
    },
    SignOut() {
      ElMessageBox.confirm(
        'Bạn muốn đăng xuất?',
        'Cảnh báo',
        {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy',
          type: 'warning',
        }
      )
        .then(() => {
          window.localStorage.removeItem("x-token-nhis");
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("cskcb");
          const redirectTo = this.$route.query.redirectTo || { name: "SignIn" };
          this.$router.push(redirectTo);
        })
        .catch(() => {

        })
    },

    closeNav() {
      this.isNavOpen = false;
    },
    toggleNavHover() {
      this.isNavOpen = true;
      this.showSubMenu();
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
      this.showSubMenu();
    },
    toggleSubMenu(item) {
      item.showSubMenu = !item.showSubMenu;
    },
    showSubMenu() {
      let arrow = document.querySelectorAll(".icoArrowRight");
      for (var i = 0; i < arrow.length; i++) {
        arrow[i].addEventListener("click", (e) => {
          let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
          arrowParent.classList.toggle("showMenu");
        });
      }
    },
    rotateIcon(index) {
      this.menus[index].isOpen = !this.menus[index].isOpen;
      // this.menus[index].isActive = !this.menus[index].isActive;
    },

    setActiveMenu() {
      this.menus.forEach((menu) => {
        let isSubMenuActive = false;

        menu.subMenu.forEach((subMenu) => {
          if (subMenu.path === window.location.pathname) {
            subMenu.isActive = true;
            isSubMenuActive = true;
          } else {
            subMenu.isActive = false;
          }
        });

        menu.isActive = menu.path === window.location.pathname || isSubMenuActive;
        menu.isOpen = menu.path === window.location.pathname || isSubMenuActive || menu.isOpen;
      });
    },
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
  created() {
    this.username = window.localStorage.getItem("username");
    this.nameHospital = window.localStorage.getItem("cskcb");
  },
  watch: {
    $route(to, from) {
      // Xử lý kiểm tra router ở đây
      // to: đối tượng route mới
      // from: đối tượng route trước đó

      // Ví dụ:
      this.setActiveMenu();

    },
  }
};
</script>

<style scoped>
.header {
  background: #ffffff;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 230px;
  background: #ffffff;
  z-index: 100;
  /* transition: all 0.5s ease; */
}

.sidebar.close {
  width: 60px;
}

.sidebar .logo-details {
  padding-left: 12px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar .logo-details i {
  font-size: 30px;
  color: #1c1c1c;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}

.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #252424;
  font-weight: 600;
}

.logo_name {
  display: flex;
  justify-content: space-between;
  width: 80%;
  justify-items: center;
}

.sidebar.close .logo-details .logo_name {
  opacity: 0;
  pointer-events: none;
}

.logo1 {
  padding-right: 16px;
}

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details {
  background: none;
}

.sidebar.close .profile-details {
  width: 78px;
}

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}

.sidebar .profile-details i {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.4s ease;
}

.sidebar.close .profile-details i {
  padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}

.sidebar .profile-details .job {
  font-size: 12px;
}

.flexbox {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.flexboxRow {
  display: flex;
  flex-direction: row;
}

.flexboxColum {
  display: flex;
  flex-direction: column;
  padding-right: 12px;
}

.openMenu {
  transition: all 0.3s;
  transform: translateX(230px);

}

.titleWeb {
  color: var(--pallate-cyan-cyan-700, #0E7490);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.titleNhis {
  padding-top: 2%;
}

.closeMenu {
  padding-left: 70px;
}

.sidebar.close~.closeMenu {
  left: 230px;
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }

  .iconMenu {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 12px;
  }
}

.avatar-menu {
  float: right;
  position: relative;
}

.avatar-menu>ul:before {
  content: '';
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fefefe;
  position: absolute;
  right: 4px;
  top: -10px;
  z-index: 99999;
}

.avatar-menu>ul {
  background-color: #fefefe;
  box-shadow: 0px 4px 5px 0px rgba(50, 50, 50, 0.5);
  clear: both;
  display: none;
  position: absolute;
  list-style-type: none;
  margin-top: 20px 0px 0px 0px;
  padding: 0px;
  top: 50px;
  right: 0px;
  width: 200px;
  z-index: 9999;
}

.avatar-menu>ul>li>a {
  color: #555;
  display: block;
  font-size: 14px;
  padding: 15px 20px;
  transition: all 0.2s ease-in-out;
}

.avatar-menu>ul>li>a span.icon {
  font-size: 16px;
  margin-right: 10px;
}

.avatar-menu>ul>li>a span.icon-ellipsis-v {
  margin-right: 2px;
}

.avatar-menu>ul>li>a:hover {
  background-color: rgba(101, 170, 173, .1);
  color: rgba(0, 0, 0, .9);
  text-decoration: none;
}

#avatar-dropdownmenu.showBox {
  display: block;
}

/* luansua */
.nav-links {
  padding-left: 0px;
}

.nav-links li {
  padding: 4px 0;
  /* margin: 8px 0; */
}

.nav-links li .iconLeft {
  width: 26px;
  height: 26px;
  margin-left: 12px;
  margin-top: 2%;
}

.link-name {
  padding-left: 12px;
  font-size: 18px;
}

.iconRight {
  margin-right: 8px;
  margin-top: 3%;
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.sidebar.close>ul>li>div>div .nameUl {
  display: none;
}

.sidebar.close>ul>li>div>img.iconRight {
  display: none;
}

.sidebar>ul>li>ul.sub-menu {
  list-style-type: disc !important;
  padding-left: 45px;
  padding-top: 6px;
}

.sidebar>ul>li>ul.sub-menu li {
  color: var(--pallate-gray-gray-300, #D1D5DB);
  padding: 8px 0;
}

.sidebar>ul>li>ul.sub-menu li>a {
  color: var(--text-primary, #020B27);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.sidebar>ul>li>ul.sub-menu li.activeSub>a {
  color: var(--pallate-cyan-cyan-700, #0E7490);
}

.sidebar>ul>li>ul.sub-menu li.activeSub {
  color: var(--pallate-cyan-cyan-700, #0E7490);
}

/* .sidebar>ul>li>ul.sub-menu li.activeSub ~  {
  color: var(--pallate-cyan-cyan-700, #0E7490);
} */
.sidebar>ul>li.open>div>img.iconRight {
  transform: rotate(90deg);
}

.sidebar>ul>li>div>div>.nameUl {
  color: #020B27;
}

.rowParent {
  height: 34px;
  display: flex;
}

.sidebar>ul>li.active>div {
  border-radius: 0px 8px 8px 0px;
  background: var(--pallate-cyan-cyan-700, #0E7490);
  color: white;
}

.sidebar>ul>li.active>div>div>.nameUl {
  color: white;
}

.link-name>.nameUl {
  margin-top: 4%;
}

.sidebar>ul>li>div>div>a {
  display: block;
}

.sidebar.close>ul>li>div>img {
  margin-top: 7%;
}

.sidebar.close .logo_thu {
  transition-delay: 3s;
  display: none;
  transition: 3s ease;
}

.nameUl {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.logoBv {
  width: 32px;
}

/* ----- */
</style>

