
import NotFound from '@/pages/NotFound'

import SignIn from '@/pages/SignIn'
import Home from '@/pages/Home'

import LogPage from '@/pages/LogPage'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'

import TongThu from '@/pages/menuPage/TongThu'
import DoanhSoNhaThuoc from '@/pages/menuPage/DoanhSoNhaThuoc'
import GetChiBHYT from '@/pages/menuPage/ChiBHYT'
import ThongKeDoanhThu from '@/pages/menuPage/ThongKeDoanhThu'
import TKXuatNhapTon from '@/pages/menuPage/TKXuatNhapTon'
import ThongKeTheoKhoa from '@/pages/menuPage/ThongKeTheoKhoa'
import ThongKeTheoDT from '@/pages/menuPage/ThongKeTheoDT'
import ThongKeKCBRealTime from '@/pages/menuPage/ThongKeKCBRealTime'
const routes = [
  {
    path: "/TongThu",
    name: "TongThu",
    component: TongThu,
  },
  {
    path: "/ThongKeKCBRealTime",
    name: "ThongKeKCBRealTime",
    component: ThongKeKCBRealTime,
  },
  {
    path: "/DoanhSoNhaThuoc",
    name: "DoanhSoNhaThuoc",
    component: DoanhSoNhaThuoc,
  },
  {
    path: "/GetChiBHYT",
    name: "GetChiBHYT",
    component: GetChiBHYT,
  },
  {
    path: "/ThongKeDoanhThu",
    name: "ThongKeDoanhThu",
    component: ThongKeDoanhThu,
  },
  {
    path: "/ThongKeTheoKhoa",
    name: "ThongKeTheoKhoa",
    component: ThongKeTheoKhoa,
  },
  {
    path: "/ThongKeTheoDT",
    name: "ThongKeTheoDT",
    component: ThongKeTheoDT,
  },
  {
    path: "/TKXuatNhapTon",
    name: "TKXuatNhapTon",
    component: TKXuatNhapTon,
  },
  {
    path: '/LogPage',
    name: 'LogPage',
    component: LogPage
  },

  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    meta: { requiresGuest: true }
  },
  {
    path: '/logout',
    name: 'SignOut',
    async beforeEnter(to, from) {
      await store.dispatch('auth/signOut')
      return { name: 'Home' }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) scroll.top = 0
    if (to.meta.smoothScroll) scroll.behavior = 'smooth'
    return scroll
  }
})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
