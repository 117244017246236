<template>
  <div class="pad">
    <div class="header-control header-thong-ke row">
      <div class="col-2 header-control-left">
      </div>
      <div class="col-10">
        <div class="header-control-rigth">
          <el-form label-position="left" label-width="70px">
            <el-form-item class="el-lable" label="">
              <el-form-item label="Khoa" class="pr-16">
                <el-select @change="handleChangeMaKhoa" class="dateThongke" v-model="paramTongThu.maKhoa"
                  placeholder="Tất cả">
                  <el-option v-for="item in itemKhoas" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-radio-group class="elRadio" @change="getValueTypeTime()" v-model="rdTypeTime">
                <el-radio-button label="0">Ngày</el-radio-button>
                <el-radio-button label="1">Tháng</el-radio-button>
                <el-radio-button label="2">Năm</el-radio-button>
              </el-radio-group>
              <el-date-picker v-model="valueNgay" class="dateThongke" :clearable="false" :type="typeDate"
                :format="formatDate" @change="handleChangDate()" />
              <span class="spaceCharacter">-</span>
              <el-date-picker v-model="valueNgayDen" class="dateThongke" :clearable="false" :type="typeDate"
                :format="formatDate" @change="handleChangDate()" />
            </el-form-item>
          </el-form>
        </div>
      </div>

    </div>
    <div class="content-wrapper row">
      <div class="col-8  pl-0">
        <div class="tableLeft">
          <label class="label-top">Thống kê doanh thu</label>
          <div class="label-right">
            <label>ĐVT: nghìn đồng</label>
            <img class="imgDownLoad" @click="printChart()" src="../../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvas"></canvas>
        </div>
      </div>
      <div class="col-4 tableRight">
        <el-table class="table-rigth-tk" :data="itemTable" empty-text="Không có dữ liệu" style="width: 100%" height="280">
          <el-table-column class-name="custom-header-row" fixed prop="ngay" :label="labelTable" />
          <el-table-column class-name="custom-header-row" prop="tonG_THU" :formatter="formatNumber" label="Tổng thu" />
          <el-table-column class-name="custom-header-row" prop="luY_KE" :formatter="formatNumber" label="Lũy kế" />
        </el-table>
        <div>
          <pageTT :totalItem="totalItem" @pagechanged="changePage" v-on:perpagechanged="perPageSTT" :displayLeft="1">
          </pageTT>
        </div>
      </div>
    </div>
    <div class="content-wrapper row contentRow2">
      <div class="col-8 pl-0">
        <div class="tableLeft">
          <label class="label-top">Thống kê chi BHYT</label>
          <div class="label-right">
            <label>ĐVT: nghìn đồng</label>
            <img class="imgDownLoad" @click="printChartTK()" src="../../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvasTK"></canvas>
        </div>
      </div>
      <div class="col-4 tableRight">
        <el-table class="table-rigth-tk" :data="itemTable_TK" empty-text="Không có dữ liệu" style="width: 100%"
          height="280">
          <el-table-column class-name="custom-header-row" fixed prop="ngay"  :label="labelTable"  />
          <el-table-column class-name="custom-header-row" prop="tonG_THU" :formatter="formatNumber" label="Chi BHYT" />
          <el-table-column class-name="custom-header-row" prop="luY_KE" :formatter="formatNumber" label="Lũy kế" />

        </el-table>
        <div>
          <pageTT :totalItem="totalItem_TK" @pagechanged="changePage_TK" v-on:perpagechanged="perPageSTT_TK"
            :displayLeft="1">
          </pageTT>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapActions } from "vuex";
import PageTT from "../../components/PageTT.vue";
import Moment from 'moment';
import { LOADING } from "../../utils/constant";
import { ElNotification } from 'element-plus';
import Chart from 'chart.js/auto';
import { bottom } from "@popperjs/core";
import { ExportChart } from "../../mixins/exportChartToImg";
import { ExcelMixin } from "../../mixins/exportExcel";
export default {
  components: { PageTT },
  mixins: [ExportChart, ExcelMixin],
  data() {
    return {
      addResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      editResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      idItemDelete: "",
      itemTable: [],
      itemTable_TK: [],
      msg: "",
      total: 0,
      totalPages: 0,
      showPopup: false,
      selectedUser: null,
      itemResource: [],
      perPage: 10,
      applyFilters: [], // Dữ liệu ban đầu
      searchDonVi: '', // Giá trị tìm kiếm cho cột "Đơn vị tiến hành"
      searchSoQuyetDinh: '', // Giá trị tìm kiếm cho cột "Số quyết định"
      searchNgayBanHanh: '', // Giá trị tìm kiếm cho cột "Ngày ban hành"
      searchNoiDung: '', // Giá trị tìm kiếm cho cột "Nội dung quyết định"
      searchDoiTuong: '', // Giá trị tìm kiếm cho cột "Đối tượng"
      searchTrangThai: '', // Giá trị tìm kiếm cho cột "Trạng Thái"
      totalItem: null,
      totalItem_TK: null,
      formatDate: "DD/MM/YYYY",
      paramTongThu: {
        tuNgay: Moment(new Date()).format('DDMMYYYY'),
        denNgay: Moment(new Date()).format('DDMMYYYY'),
        maKhoa: "-1",
        doiTuong: -1,
        index: 1,
        pagesize: 10,
        formatDate: 'DDMMYYYY'
      },
      paramTongThu_TK: {
        tuNgay: Moment(new Date()).format('DDMMYYYY'),
        denNgay: Moment(new Date()).format('DDMMYYYY'),
        maKhoa: "-1",
        doiTuong: -1,
        index: 1,
        pagesize: 10,
        formatDate: 'DDMMYYYY'
      },
      valueNgay: new Date(),
      valueNgayDen: new Date(),
      itemKhoas: [],
      itemDT: [
        {
          value: -1,
          label: 'Tất cả',
        },
        {
          value: 1,
          label: 'Bảo hiểm',
        },
        {
          value: 0,
          label: 'Dịch vụ',
        }
      ],
      rdTypeTime: "0",
      typeDate: "date",
      lableChartTT: [],
      dataSetChartTT: [],
      dataTableTT: {
        label: '',
        data: [],
        backgroundColor: 'rgba(71, 91, 232, 1)',
        borderRadius: 5,
        order: 0
      },
      dataTableLK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      chart: null,
      chartTK: null,

      lableChartTT_TK: [],
      dataSetChartTT_TK: [],
      dataTableTT_TK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(71, 91, 232, 1)',
        borderRadius: 5,
        order: 0
      },
      dataTableLK_TK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      labelTable: 'Ngày'
    };
  },

  created() {
    this.get();
    this.get_TK();
    this.getKhoa();
  },
  methods: {
    ...mapActions('thongke', ['GetTongThuTK', 'getKhoas', 'GetChiBHYTTK', 'GetTongThuTKExport', 'GetChiBHYTTKExport']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    get() {
      this.setDefautlChart();
      this.paramTongThu.tuNgay = Moment(this.valueNgay).format(this.formatDate);
      this.paramTongThu.denNgay = Moment(this.valueNgayDen).format(this.formatDate);
      this.paramTongThu.formatDate = this.formatDate;
      this.GetTongThuTK(this.paramTongThu).then((res) => {
        this.itemTable = res.item.lstItem;
        this.totalItem = res.item.totalRecords;
      }).finally(
        () => {
          this.itemTable.forEach(element => {
            this.lableChartTT.push(element.ngay);
            this.dataTableTT.data.push(element.tonG_THU);
            this.dataTableLK.data.push(element.luY_KE);
          });
          this.renderChart();
        }
      )
    },
    get_TK() {
      this.showLoading(true);
      this.setDefautlChart_TK();
      this.paramTongThu_TK.tuNgay = Moment(this.valueNgay).format(this.formatDate);
      this.paramTongThu_TK.denNgay = Moment(this.valueNgayDen).format(this.formatDate);
      this.paramTongThu_TK.formatDate = this.formatDate;
      this.GetChiBHYTTK(this.paramTongThu_TK).then((res) => {
        this.itemTable_TK = res.item.lstItem;
        this.totalItem_TK = res.item.totalRecords;
      }).finally(
        () => {
          this.showLoading(false);
          this.itemTable_TK.forEach(element => {
            this.lableChartTT_TK.push(element.ngay);
            this.dataTableTT_TK.data.push(element.tonG_THU);
            this.dataTableLK_TK.data.push(element.luY_KE);
          });
          this.renderChart_TK();
        }
      )
    },
    setDefautlChart() {
      this.lableChartTT = [];
      this.dataTableTT.label = "Tổng thu";
      this.dataTableLK.label = "Lũy kế";
      this.dataTableTT.data = [];
      this.dataTableLK.data = [];
      this.dataTableTT.backgroundColor = "rgba(8, 145, 178, 1)";
      this.dataTableLK.backgroundColor = "rgba(242, 154, 46, 1)";
      this.dataTableTT.type = 'bar';
      this.dataTableLK.type = 'line';
      this.dataTableLK.borderColor = 'rgba(242, 154, 46, 1)';
      this.dataTableTT.borderRadius = 5;
      this.dataTableTT.order = 0;
      this.dataTableLK.order = 1;
    },
    setDefautlChart_TK() {
      this.dataTableTT_TK.label = "Chi BHYT";
      this.dataTableLK_TK.label = "Lũy kế";
      this.dataTableTT_TK.data = [];
      this.dataTableLK_TK.data = [];
      this.lableChartTT_TK = [];
      this.dataTableTT_TK.backgroundColor = "rgba(8, 145, 178, 1)";
      this.dataTableLK_TK.backgroundColor = "rgba(242, 154, 46, 1)";
      this.dataTableTT_TK.type = 'bar';
      this.dataTableLK_TK.type = 'line';
      this.dataTableLK_TK.borderColor = 'rgba(242, 154, 46, 1)';
      this.dataTableTT_TK.borderRadius = 5;
      this.dataTableTT_TK.order = 0;
      this.dataTableLK_TK.order = 1;
    },
    getKhoa() {
      this.getKhoas().then((res) => {
        this.itemKhoas = res.items
      }).finally(() => {
      });
    },
    changePage(page) {
      this.paramTongThu.index = page;
      this.get();
    },
    changePage_TK(page) {
      this.paramTongThu_TK.index = page;
      this.get_TK();
    },
    perPageSTT(perPage) {
      this.paramTongThu.pagesize = perPage;
      this.paramTongThu.index = 1;
      this.get();
    },
    perPageSTT_TK(perPage) {
      this.paramTongThu_TK.pagesize = perPage;
      this.paramTongThu_TK.index = 1;
      this.get_TK();
    },
    handleChangDate() {
      if (this.valueNgay > this.valueNgayDen) {
        ElNotification.error({
          title: 'Cảnh báo',
          message: 'Từ ngày không được lớn hơn đến ngày',
          offset: 50,
          duration: 2500
        })
      }
      this.get();
      this.get_TK();
    },
    getValueTypeTime() {
      if (this.rdTypeTime == 0) {
        this.typeDate = "date";
        this.formatDate = "DD/MM/YYYY";
        this.labelTable = 'Ngày';
      }
      else if (this.rdTypeTime == 1) {
        this.typeDate = "month";
        this.formatDate = "MM/YYYY";
        this.labelTable = 'Tháng';
      }
      else if (this.rdTypeTime == 2) {
        this.typeDate = "year";
        this.formatDate = "YYYY";
        this.labelTable = 'Năm';
      }
      this.get();
      this.get_TK();
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.lableChartTT,
          datasets: [this.dataTableTT, this.dataTableLK],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
              position: bottom,

            },
          }

        },

      });
    },
    printChart() {
      const canvas = this.$refs.chartCanvas;
      const filename = 'thongkedoanhthu_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
      this.exportToExcel();
    },
    printChartTK() {
      const canvas = this.$refs.chartCanvasTK;
      const filename = 'thongkechiBHYT_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
      this.exportToExcelTK();
    },
    renderChart_TK() {
      const ctxTK = this.$refs.chartCanvasTK.getContext('2d');
      if (this.chartTK) {
        this.chartTK.destroy();
      }
      this.chartTK = new Chart(ctxTK, {
        type: 'bar',
        data: {
          labels: this.lableChartTT_TK,
          datasets: [this.dataTableTT_TK, this.dataTableLK_TK],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
              position: bottom
            }
          }

        },

      });
    },
    handleChangeMaKhoa() {
      this.paramTongThu_TK.maKhoa = this.paramTongThu.maKhoa;
      this.get();
      this.get_TK();
    },
    formatNumber(row, column) {
      return parseFloat(row[column.property]).toLocaleString();
    },
    exportToExcel() {
      const header = ['STT', 'Ngày', 'Tổng thu', 'Lũy kề'];
      const filename = 'thongkedoanhthu_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetTongThuTKExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem;
      }).finally(() => {
        this.showLoading(false);
        this.fillDataToExcel(dataEx, header, filename);
      })
    },
    exportToExcelTK() {
      const header = ['STT', 'Ngày', 'Tổng thu', 'Lũy kề'];
      const filename = 'thongkechiBHYT_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetChiBHYTTKExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem;
      }).finally(() => {
        this.showLoading(false);
        this.fillDataToExcel(dataEx, header, filename);
      })
    },
  },
  computed: {
  }
};
</script>

<style scoped>
.pad {
  padding: 8px 16px;
  height: 100vh;
  overflow-y: auto;
}

.form-perpage1 {
  color: black;
  font-weight: 400;
}

.form-perpage2 {
  color: black;
  font-weight: 300;
}

.content-wrapper {
  /* background-color: #ffffff; */
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.status-cell {
  padding: 4px;
  border-radius: 4px;
}

.select-input {
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px;
  gap: 10px;
  border: 1px solid #727981;
}

.header-control {
  padding-top: 0;
  justify-content: space-between;
  align-items: center;
}

.header-control-left {
  display: flex;
}

.header-control-rigth {
  float: right;
}

.input-control {
  max-width: 222px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-bottom: 10px;
}

.fontsize_13 {
  font-size: 13px;
}

.header-thong-ke {
  background: var(--base-background, #F5F8FA);
  padding-top: 0.7%;
  height: 56px;
  margin: 0px;
  border-radius: 5px;
}

.tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

.table th {
  background-color: var(--pallate-blue-blue-100, #DBEAFE) !important;
  color: #020B27;
}

.tableRight {
  padding: 0px;
}

.chart_Canvas {
  min-height: 274px !important;
  width: auto !important;
  max-height: 274px !important;
}

.table-container {
  height: 284px;
  margin-bottom: 0px;
  font-size: 14px;
}

.table td {
  padding: 3px;
}

.tableLeft {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 56px 24px 0px 24px;
}

.tableRight {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 0px;
}

.contentRow2 {
  margin-bottom: 30px;
}

.table>tbody {
  padding-left: 10px;
}

.label-top {
  position: absolute;
  left: 24px;
  top: 16px;
  color: var(--text-primary, #020B27);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
}

.label-right {
  position: absolute;
  right: 24px;
  top: 16px;
  color: var(--pallate-gray-gray-400, #9CA3AF);
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
}

.label-right label {
  margin-right: 16px;
}

.el-input {
  width: 150px !important;
}
</style>
