<template>
  <router-link :to="{ name: 'Home' }">Read some cool threads</router-link>
  <div class="paginators">
    <a> <el-icon>
        <CaretLeft />
      </el-icon> </a>
    <el-input v-model="input" /> <span>/</span> <label>8</label>
    <a> <el-icon>
        <CaretRight />
      </el-icon> </a>
  </div>
</template>

<script>
// import sideBar from "../components/sideBar.vue";
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},

  created() { },

  mounted() { },
};
</script>

<style>
.paginators {
  left: 600px;
  width: 96px;
  border: 1px solid #808191;
  border-radius: 5px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginators .el-input {
  width: 35px;
  height: 20px;
}

.paginators a {
  height: 20px;
}

.paginators span {
  height: 40px;
  font-size: 23px;
}
</style>
