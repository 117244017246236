<template>
  <div class="pad">
    <div class="header-control header-thong-ke row">
      <div class="col-2 header-control-left">

      </div>
      <div class="col-10">
        <div class="header-control-rigth">
          <el-form label-position="left" label-width="70px">
            <el-form-item class="el-lable" label="">
              <el-form-item label="Kho" class="pr-16">
                <el-select @change="handleChangeMaKhoa" class="dateThongke" v-model="paramTongThu.maKho"
                  placeholder="Tất cả">
                  <el-option v-for="item in itemKhoas" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-radio-group class="elRadio" @change="getValueTypeTime()" v-model="rdTypeTime">
                <el-radio-button label="0">Ngày</el-radio-button>
                <el-radio-button label="1">Tháng</el-radio-button>
                <el-radio-button label="2">Năm</el-radio-button>
              </el-radio-group>
              <el-date-picker v-model="valueNgay" class="dateThongke" :clearable="false" :type="typeDate"
                :format="formatDate" @change="handleChangDate()" />
              <span class="spaceCharacter">-</span>
              <el-date-picker v-model="valueNgayDen" class="dateThongke" :clearable="false" :type="typeDate"
                :format="formatDate" @change="handleChangDate()" />
            </el-form-item>
          </el-form>
        </div>
      </div>

    </div>
    <div class="content-wrapper row">
      <div class="col-8  pl-0">
        <div class="tableLeft">
          <label class="label-top">Thống kê theo thời gian</label>
          <div class="label-right">
            <label>ĐVT: Sản phẩm</label>
            <img class="imgDownLoad" @click="printChart()" src="../../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvas"></canvas>
        </div>
      </div>
      <div class="col-4 tableRight">
        <el-table class="table-rigth-tk" :data="itemTable" empty-text="Không có dữ liệu" style="width: 100%" height="280">
          <el-table-column class-name="custom-header-row" fixed prop="ngay" :label="labelTable" />
          <el-table-column class-name="custom-header-row" prop="sO_LUONG_NHAP" :formatter="formatNumber" label="Nhập" />
          <el-table-column class-name="custom-header-row" prop="sO_LUONG_XUAT" :formatter="formatNumber" label="Xuất" />
          <el-table-column class-name="custom-header-row" prop="sL_TON" :formatter="formatNumber" label="Tồn" />
        </el-table>

        <div>
          <pageTT :totalItem="totalItem" @pagechanged="changePage" v-on:perpagechanged="perPageSTT" :displayLeft="1">
          </pageTT>
        </div>
      </div>
    </div>
    <div class="content-wrapper row contentRow2">
      <div class="col-8 pl-0">
        <div class="tableLeft">
          <label class="label-top">Thống kê theo kho</label>
          <div class="label-right">
            <label>ĐVT: Sản phẩm</label>
            <img class="imgDownLoad" @click="printChartTK()" src="../../assets/images/btnSave.png" alt="">
          </div>
          <canvas class="chart_Canvas" ref="chartCanvasTK"></canvas>
        </div>
      </div>
      <div class="col-4 tableRight">
        <el-table  class="table-rigth-tk" :data="itemTable_TK" empty-text="Không có dữ liệu" style="width: 100%" height="280">
          <el-table-column class-name="custom-header-row" fixed prop="kho" label="Kho" />
          <el-table-column class-name="custom-header-row" prop="sO_LUONG_NHAP" :formatter="formatNumber" label="Nhập" />
          <el-table-column class-name="custom-header-row" prop="sO_LUONG_XUAT" :formatter="formatNumber" label="Xuất" />
          <el-table-column class-name="custom-header-row" prop="sL_TON" :formatter="formatNumber" label="Tồn" />
        </el-table>
        <div>
          <pageTT :totalItem="totalItem_TK" @pagechanged="changePage_TK" v-on:perpagechanged="perPageSTT_TK"
            :displayLeft="1">
          </pageTT>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapActions } from "vuex";
import PageTT from "../../components/PageTT.vue";
import Moment from 'moment';
import { LOADING } from "../../utils/constant";
import { ElNotification } from 'element-plus';
import Chart from 'chart.js/auto';
import { bottom } from "@popperjs/core";
import { ExportChart } from "../../mixins/exportChartToImg";
import { ExcelMixin } from "../../mixins/exportExcel"
export default {
  components: { PageTT },
  mixins: [ExportChart, ExcelMixin],
  data() {
    return {
      addResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      editResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      idItemDelete: "",
      itemTable: [],
      itemTable_TK: [],
      msg: "",
      total: 0,
      totalPages: 0,
      showPopup: false,
      selectedUser: null,
      itemResource: [],
      perPage: 10,
      applyFilters: [], // Dữ liệu ban đầu
      searchDonVi: '', // Giá trị tìm kiếm cho cột "Đơn vị tiến hành"
      searchSoQuyetDinh: '', // Giá trị tìm kiếm cho cột "Số quyết định"
      searchNgayBanHanh: '', // Giá trị tìm kiếm cho cột "Ngày ban hành"
      searchNoiDung: '', // Giá trị tìm kiếm cho cột "Nội dung quyết định"
      searchDoiTuong: '', // Giá trị tìm kiếm cho cột "Đối tượng"
      searchTrangThai: '', // Giá trị tìm kiếm cho cột "Trạng Thái"
      totalItem: null,
      totalItem_TK: null,
      formatDate: "DD/MM/YYYY",
      paramTongThu: {
        tuNgay: Moment(new Date()).format('DDMMYYYY'),
        denNgay: Moment(new Date()).format('DDMMYYYY'),
        maKho: "-1",
        doiTuong: -1,
        index: 1,
        pagesize: 10,
        formatDate: 'DDMMYYYY'
      },
      paramTongThu_TK: {
        tuNgay: Moment(new Date()).format('DDMMYYYY'),
        denNgay: Moment(new Date()).format('DDMMYYYY'),
        maKho: "-1",
        doiTuong: -1,
        index: 1,
        pagesize: 10,
        formatDate: 'DDMMYYYY'
      },
      valueNgay: new Date(),
      valueNgayDen: new Date(),
      itemKhoas: [],
      itemDT: [
        {
          value: -1,
          label: 'Tất cả',
        },
        {
          value: 1,
          label: 'Bảo hiểm',
        },
        {
          value: 0,
          label: 'Dịch vụ',
        }
      ],
      rdTypeTime: "0",
      typeDate: "date",
      lableChartTT: [],
      dataSetChartTT: [],
      dataTableTT: {
        label: '',
        data: [],
        backgroundColor: 'rgba(71, 91, 232, 1)',
        borderRadius: 5,
        order: 0
      },
      dataTableLK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      dataTableTon: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      chart: null,
      chartTK: null,

      lableChartTT_TK: [],
      dataSetChartTT_TK: [],
      dataTableTT_TK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(71, 91, 232, 1)',
        borderRadius: 5,
        order: 0
      },
      dataTableLK_TK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      dataTableTon_TK: {
        label: '',
        data: [],
        backgroundColor: 'rgba(242, 154, 46, 1)',
        type: 'line',
        borderColor: '',
        order: 1
      },
      ctx: null,
      ctx_TK: null,
      labelTable: 'Ngày'
    };
  },

  created() {
    this.get();
    this.get_TK();
    this.getKhoa();
  },
  mounted() {
    this.renderChartBefore();

  },
  methods: {
    ...mapActions('thongke', ['GetTonTKThoiGian', 'getKho', 'GetTonTKKho', 'GetTonTKKhoExport', 'GetTonTKThoiGianExport']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    renderChartBefore() {
      this.ctx = this.$refs.chartCanvas;
      this.ctx_TK = this.$refs.chartCanvasTK;
      this.renderChart();
      this.renderChart_TK();
    },
    get() {
      this.showLoading(true);
      this.setDefautlChart();
      this.paramTongThu.tuNgay = Moment(this.valueNgay).format(this.formatDate);
      this.paramTongThu.denNgay = Moment(this.valueNgayDen).format(this.formatDate);
      this.paramTongThu.formatDate = this.formatDate;
      this.GetTonTKThoiGian(this.paramTongThu).then((res) => {
        this.itemTable = res.item.lstItem;
        this.totalItem = res.item.totalRecords;
      }).finally(
        () => {
          this.setChart().then().finally(
            () => {
              this.chart.destroy();
              this.renderChart().finally(this.showLoading(false))
            }

          )

        }
      )
    },
    setChart() {
      return new Promise((resolve) => {
        this.itemTable.forEach(element => {
          this.lableChartTT.push(element.ngay);
          this.dataTableTT.data.push(element.sO_LUONG_NHAP);
          this.dataTableLK.data.push(element.sO_LUONG_XUAT);
          this.dataTableTon.data.push(element.sL_TON);
        });
        this.showLoading(false);
        resolve();
      })
    },
    setChart_TK() {
      return new Promise((resolve) => {
        this.itemTable_TK.forEach(element => {
          this.lableChartTT_TK.push(element.kho);
          this.dataTableTT_TK.data.push(element.sO_LUONG_NHAP);
          this.dataTableLK_TK.data.push(element.sO_LUONG_XUAT);
          this.dataTableTon_TK.data.push(element.sL_TON);
        });

        this.showLoading(false);
        resolve();
      })
    },
    get_TK() {
      this.showLoading(true);
      this.setDefautlChart_TK();
      this.paramTongThu_TK.tuNgay = Moment(this.valueNgay).format(this.formatDate);
      this.paramTongThu_TK.denNgay = Moment(this.valueNgayDen).format(this.formatDate);
      this.paramTongThu_TK.formatDate = this.formatDate;
      this.GetTonTKKho(this.paramTongThu_TK).then((res) => {
        this.itemTable_TK = res.item.lstItem;
        this.totalItem_TK = res.item.totalRecords;
      }).finally(
        () => {
          this.setChart_TK().then(() => { }).finally(
            this.renderChart_TK())
        }
      )
    },
    setDefautlChart() {
      this.lableChartTT = [];
      this.dataTableTT.label = "Nhập";
      this.dataTableTT.data = [];
      this.dataTableTT.backgroundColor = "rgba(34, 197, 94, 1)";
      this.dataTableTT.type = 'bar';
      this.dataTableTT.borderRadius = 5;
      this.dataTableTT.order = 0;

      this.dataTableLK.data = [];
      this.dataTableLK.label = "Xuất";
      this.dataTableLK.type = 'bar';
      this.dataTableLK.backgroundColor = "rgba(59, 130, 246, 1)";
      this.dataTableLK.order = 1;

      this.dataTableTon.data = [];
      this.dataTableTon.label = "Tồn";
      this.dataTableTon.type = 'bar';
      this.dataTableTon.backgroundColor = "rgba(202, 138, 4, 1)";
      this.dataTableTon.order = 1;
    },
    setDefautlChart_TK() {
      this.lableChartTT_TK = [];
      this.dataTableTT_TK.label = "Nhập";
      this.dataTableTT_TK.data = [];
      this.dataTableTT_TK.backgroundColor = "rgba(34, 197, 94, 1)";
      this.dataTableTT_TK.type = 'bar';
      this.dataTableTT_TK.borderRadius = 5;
      this.dataTableTT_TK.order = 0;

      this.dataTableLK_TK.data = [];
      this.dataTableLK_TK.label = "Xuất";
      this.dataTableLK_TK.type = 'bar';
      this.dataTableLK_TK.backgroundColor = "rgba(59, 130, 246, 1)";
      this.dataTableLK_TK.order = 1;

      this.dataTableTon_TK.data = [];
      this.dataTableTon_TK.label = "Tồn";
      this.dataTableTon_TK.type = 'bar';
      this.dataTableTon_TK.backgroundColor = "rgba(202, 138, 4, 1)";
      this.dataTableTon_TK.order = 1;
    },
    getKhoa() {
      this.getKho().then((res) => {
        this.itemKhoas = res.items
      }).finally(() => {
      });
    },
    changePage(page) {
      this.paramTongThu.index = page;
      this.get();
    },
    changePage_TK(page) {
      this.paramTongThu_TK.index = page;
      this.get_TK();
    },
    perPageSTT(perPage) {
      this.paramTongThu.pagesize = perPage;
      this.paramTongThu.index = 1;
      this.get();
    },
    perPageSTT_TK(perPage) {
      this.paramTongThu_TK.pagesize = perPage;
      this.paramTongThu_TK.index = 1;
      this.get_TK();
    },
    handleChangDate() {
      if (this.valueNgay > this.valueNgayDen) {
        ElNotification.error({
          title: 'Cảnh báo',
          message: 'Từ ngày không được lớn hơn đến ngày',
          offset: 50,
          duration: 2500
        })
      }
      this.get();
      this.get_TK();
    },
    getValueTypeTime() {
      if (this.rdTypeTime == 0) {
        this.typeDate = "date";
        this.formatDate = "DD/MM/YYYY";
        this.labelTable = 'Ngày';
      }
      else if (this.rdTypeTime == 1) {
        this.typeDate = "month";
        this.formatDate = "MM/YYYY";
        this.labelTable = 'Tháng';
      }
      else if (this.rdTypeTime == 2) {
        this.typeDate = "year";
        this.formatDate = "YYYY";
        this.labelTable = 'Năm';
      }
      this.get();
      this.get_TK();
    },
    renderChart() {
      return new Promise((resolve) => {
        this.chart = new Chart(this.ctx, {
          type: 'bar',
          data: {
            labels: this.lableChartTT,
            datasets: [this.dataTableTT, this.dataTableLK, this.dataTableTon],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  pointStyle: "rectRounded",
                },
                position: bottom
              },
            },
          },

        });
        resolve();
      })
    },
    renderChart_TK() {
      if (this.chartTK !== null) {
        this.chartTK.destroy();
      }
      this.chartTK = new Chart(this.ctx_TK, {
        type: 'bar',
        data: {
          labels: this.lableChartTT_TK,
          datasets: [this.dataTableTT_TK, this.dataTableLK_TK, this.dataTableTon_TK],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
              },
              position: bottom
            }
          }

        },

      });
    },
    handleChangeMaKhoa() {
      this.paramTongThu_TK.maKho = this.paramTongThu.maKho;
      this.get();
      this.get_TK();
    },
    formatNumber(row, column) {
      return parseFloat(row[column.property]).toLocaleString();
    },
    printChart() {
      const canvas = this.$refs.chartCanvas;
      const filename = 'xuatnhaptontheothoigian_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
      this.exportToExcel();
    },
    printChartTK() {
      const canvas = this.$refs.chartCanvasTK;
      const filename = 'xuatnhaptontheokho_' + Moment().format('DDMMYYYYHHmmss') + '.png';
      this.exportChartToImage(canvas, filename);
      this.exportToExcelTK();
    },
    exportToExcel() {
      const header = ['STT', 'Ngày', 'Nhập', 'Xuất', 'Tồn'];
      const filename = 'xuatnhaptontheothoigian_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetTonTKThoiGianExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem.map(obj => {
          const { kho, ...rest } = obj;
          return rest;
        });
      }).finally(() => {
        this.showLoading(false);
        this.fillDataToExcel(dataEx, header, filename);
      })
    },
    exportToExcelTK() {
      const header = ['STT', 'Kho', 'Nhập', 'Xuất', 'Tồn'];
      const filename = 'xuatnhaptontheokho_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetTonTKKhoExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem.map(obj => {
          const { ngay, ...rest } = obj;
          return rest;
        });
      }).finally(() => {
        this.showLoading(false);
        this.fillDataToExcel(dataEx, header, filename);
      })
    },
  },
  computed: {
  }
};
</script>

<style scoped>
.pad {
  padding: 8px 16px;
  height: 100vh;
  overflow-y: auto;
}

.form-perpage1 {
  color: black;
  font-weight: 400;
}

.form-perpage2 {
  color: black;
  font-weight: 300;
}

.content-wrapper {
  /* background-color: #ffffff; */
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.status-cell {
  padding: 4px;
  border-radius: 4px;
}

.select-input {
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px;
  gap: 10px;
  border: 1px solid #727981;
}

.header-control {
  padding-top: 0;
  justify-content: space-between;
  align-items: center;
}

.header-control-left {
  display: flex;
}

.header-control-rigth {
  float: right;
}

.input-control {
  max-width: 222px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-bottom: 10px;
}

.fontsize_13 {
  font-size: 13px;
}


.header-thong-ke {
  background: var(--base-background, #F5F8FA);
  padding-top: 0.7%;
  height: 56px;
  margin: 0px;
  border-radius: 5px;
}

.tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

.table th {
  background-color: var(--pallate-blue-blue-100, #DBEAFE) !important;
  color: #020B27;
}

.tableRight {
  padding: 0px;
}

.chart_Canvas {
  min-height: 274px !important;
  width: auto !important;
  max-height: 274px !important;
}

.table-container {
  height: 284px;
  margin-bottom: 0px;
  font-size: 14px;
}

.table td {
  padding: 3px;
}

.tableLeft {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 56px 24px 0px 24px;
}

.tableRight {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 0px;
}

.contentRow2 {
  margin-bottom: 30px;
}

.table>tbody {
  padding-left: 10px;
}

.label-top {
  position: absolute;
  left: 24px;
  top: 16px;
  color: var(--text-primary, #020B27);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px;
}

.label-right {
  position: absolute;
  right: 24px;
  top: 16px;
  color: var(--pallate-gray-gray-400, #9CA3AF);
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
}

.label-right label {
  margin-right: 16px;
}
</style>
