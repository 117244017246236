import html2canvas from 'html2canvas';

export const ExportChart = {
  methods: {
    exportChartToImage(canvas, fileName) {
        html2canvas(canvas).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = fileName;
            link.click();
          });
    }
  }
};