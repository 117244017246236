<template>
  <div class="pad">
    <div class="header-control row">
      <div class="col-6 header-control-left">
        <div class="boxTT">
          <div class="boxTT_img">
            <div>
              <img src="../../assets/images/iconDoanhThuBox.svg" alt="">
            </div>
          </div>
          <div class="boxTT_text">
            <lable class="boxTT_Tiltle">Tổng thu</lable>
            <lable class="boxTT_Total">{{ parseFloat(total).toLocaleString() }}</lable>
          </div>
        </div>
        <div class="boxTT">
          <div class="boxTT_img">
            <div>
              <img src="../../assets/images/iconSoBenhNhan.svg" alt="">
            </div>
          </div>
          <div class="boxTT_text">
            <lable class="boxTT_Tiltle">Số bệnh nhân</lable>
            <lable class="boxTT_Total">{{ totalItem }}</lable>
          </div>
        </div>
      </div>
      <div class="col-6 ">
        <div class="header-control-rigth">
          <el-form label-position="left" label-width="120px">
            <el-form-item class="el-lable" label="Thời gian">
              <el-date-picker v-model="valueNgay" class="dateThongke" :clearable="false" type="date" format="DD/MM/YYYY"
                @change="handleChangDate()" />
              <span class="spaceCharacter">-</span>
              <el-date-picker v-model="valueNgayDen" class="dateThongke" :clearable="false" type="date"
                format="DD/MM/YYYY" @change="handleChangDate()" />
            </el-form-item>
            <el-form-item label="Khoa">
              <el-select @change="get()" v-model="paramTongThu.maKhoa" placeholder="Tất cả">
                <el-option v-for="item in itemKhoas" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="Đối tượng">
              <el-select @change="get()" v-model="paramTongThu.doiTuong" placeholder="Tất cả">
                <el-option v-for="item in itemDT" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </div>
    <div class="content-wrapper ">
      <div class="table-row-download">
        <img src="../../assets/images/btnSave.png" @click="exportToExcel()" alt="">
      </div>
      <div class="table-container">
        <table class="table table-hover">
          <thead>
            <tr class="background">
              <th class="center-header-left columnSTT">
                <span class="fontsize_13 ">STT</span>
              </th>

              <th class="left-header column18">

                <span class="fontsize_13">Ngày</span>
              </th>
              <th class="left-header column18">

                <span class="fontsize_13">Hồ sơ</span>
              </th>
              <th class="left-header column18">
                <span class="fontsize_13">Thu bệnh nhân</span>
              </th>
              <th class="left-header column18">

                <span class="fontsize_13">Giảm trừ</span>
              </th>
              <th class="left-header columnAuto">

                <span class="fontsize_13">Tổng thu</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="itemTable.length > 0" v-for="(resource, index) in itemTable" :key="resource._id">
              <td class="text-center">{{ resource.rnum }}</td>
              <td class="text-left">{{ resource.ngay }}</td>
              <td class="text-left">{{ resource.sO_HO_SO }}</td>
              <td class="text-left">{{ resource.sO_TIEN_THU }}</td>
              <td class="text-left">
                {{ resource.tieN_TRICH }}
              </td>
              <td class="text-left">
                {{ resource.tonG_THU }}
              </td>
            </tr>
            <tr v-if="itemTable.length == 0">
              <td colspan="6" class="text-center">
                Không có dữ liệu
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <div class="footer-bgwhite">
        <pageTT :totalItem="totalItem" @pagechanged="changePage" v-on:perpagechanged="perPageSTT"></pageTT>
      </div>

    </div>
  </div>
</template>
<script>

import { mapMutations, mapActions } from "vuex";
import PageTT from "../../components/PageTT.vue";
import Moment from 'moment';
import { LOADING } from "../../utils/constant";
import { ElNotification } from 'element-plus';
import { ExcelMixin } from "../../mixins/exportExcel"
export default {
  components: { PageTT },
  mixins: [ExcelMixin],
  data() {
    return {
      addResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      editResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      idItemDelete: "",
      itemTable: [],
      msg: "",
      total: 0,
      totalPages: 0,
      showPopup: false,
      selectedUser: null,
      itemResource: [],
      perPage: 10,
      applyFilters: [], // Dữ liệu ban đầu
      searchDonVi: '', // Giá trị tìm kiếm cho cột "Đơn vị tiến hành"
      searchSoQuyetDinh: '', // Giá trị tìm kiếm cho cột "Số quyết định"
      searchNgayBanHanh: '', // Giá trị tìm kiếm cho cột "Ngày ban hành"
      searchNoiDung: '', // Giá trị tìm kiếm cho cột "Nội dung quyết định"
      searchDoiTuong: '', // Giá trị tìm kiếm cho cột "Đối tượng"
      searchTrangThai: '', // Giá trị tìm kiếm cho cột "Trạng Thái"
      totalItem: null,
      paramTongThu: {
        tuNgay: Moment(new Date()).format('DDMMYYYY'),
        denNgay: Moment(new Date()).format('DDMMYYYY'),
        maKhoa: "-1",
        doiTuong: -1,
        index: 1,
        pagesize: 10
      },
      valueNgay: new Date(),
      valueNgayDen: new Date(),
      itemKhoas: [],
      itemDT: [
        {
          value: -1,
          label: 'Tất cả',
        },
        {
          value: 1,
          label: 'Bảo hiểm',
        },
        {
          value: 0,
          label: 'Dịch vụ',
        }
      ]
    };
  },

  created() {
    this.get();
    this.getKhoa();
  },

  methods: {
    ...mapActions('thongke', ['GetTongThu', 'getKhoas', 'GetTongThuExport']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    formatNumber(numberIn) {
      // const absoluteNumber = Math.abs(numberIn);
      // let formattedNumber = '';
      // if (absoluteNumber >= 1000000000) {
      //   formattedNumber = (absoluteNumber / 1000000000).toFixed(2) + 'B';
      // } else if (absoluteNumber >= 1000000) {
      //   formattedNumber = (absoluteNumber / 1000000).toFixed(2) + 'M';
      // } else if (absoluteNumber >= 1000) {
      //   formattedNumber = (absoluteNumber / 1000).toFixed(0) + 'K';
      // } else {
      //   formattedNumber = absoluteNumber.toString();
      // }
      // return (numberIn < 0 ? '-' : '') + formattedNumber;
    },
    exportToExcel() {
      const header = ['STT', 'Thời gian', 'Số hồ sơ', 'Thu bệnh nhân', 'Giảm trừ', 'Tổng thu'];
      const filename = 'TongThu_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetTongThuExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem;
      }).finally(() => {
        this.showLoading(false);
        this.fillDataToExcel(dataEx, header, filename);
      })

    },
    get() {
      this.showLoading(true);
      this.GetTongThu(this.paramTongThu).then((res) => {
        this.itemTable = res.item.lstItem;
        this.totalItem = res.item.totalRecords;
        this.total = res.item.total;
      }).finally(this.showLoading(false))

    },
    getKhoa() {
      this.showLoading(true);
      this.getKhoas().then((res) => {
        this.itemKhoas = res.items
      }).finally(() => {
        this.showLoading(false);
      });
    },
    changePage(page) {
      this.paramTongThu.index = page;
      this.get();
    },
    perPageSTT(perPage) {
      this.paramTongThu.pagesize = perPage;
      this.paramTongThu.index = 1;
      this.get();
    },
    gotoPage(page) {
      this.paramTongThu.index = page;
      // this.getPermissions(this.paramTongThu.index);
    },
    nextPage() {
      if (this.paramTongThu.index < this.totalPages) {
        this.paramTongThu.index++;
        // this.getPermissions(this.paramTongThu.index);
      }
    },
    handlePageChange(event) {
      const selectedPage = event.page; // Lấy số trang được chọn
      this.paramTongThu.index = selectedPage;
    },
    handleValueChange(event) {
      const selectedRowsPerPage = event.value;
      this.paramTongThu.pagesize = selectedRowsPerPage;
      this.paramTongThu.index = 1;

    },
    handleChangDate() {
      if (this.valueNgay > this.valueNgayDen) {
        ElNotification.error({
          title: 'Cảnh báo',
          message: 'Từ ngày không được lớn hơn đến ngày',
          offset: 50,
          duration: 2500
        })
      }
      this.paramTongThu.tuNgay = Moment(this.valueNgay).format('DDMMYYYY');
      this.paramTongThu.denNgay = Moment(this.valueNgayDen).format('DDMMYYYY');
      this.get();
    },
    previousPage() {
      if (this.paramTongThu.index > 1) {
        this.paramTongThu.index--;
      }
    },
    openPopup(user) {
      this.showPopup = true;
      this.selectedUser = user;
    },
    closePopup() {
      this.showPopup = false;
      this.selectedUser = null;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${day}-${month}-${year}`;
    },

  },
  computed: {
  }
};
</script>

<style scoped>
.pad {
  padding: 8px 16px;
  height: 100vh;
  overflow-y: auto;
}

.form-perpage1 {
  color: black;
  font-weight: 400;
}

.form-perpage2 {
  color: black;
  font-weight: 300;
}

.content-wrapper {
  background-color: var(--base-background, #F5F8FA);
  /* padding: 20px; */
  border-radius: 10px;
  margin-top: 20px;
}

.status-cell {
  padding: 4px;
  border-radius: 4px;
}

.select-input {
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px;
  gap: 10px;
  border: 1px solid #727981;
}

.header-control {
  padding-top: 0;
  justify-content: space-between;
  align-items: center;
}

.header-control-left {
  display: flex;
}

.header-control-rigth {
  float: right;
}

.input-control {
  max-width: 222px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-bottom: 10px;
}

.el-select {
  width: 100%;
}
</style>
