import { createI18n } from "vue-i18n";
import enMessage from "@/lang/en.json";
import jpMessage from "@/lang/jp.json";
import Cookies from "js-cookie";

const messages = {
  EN: enMessage,
  JP: jpMessage
};

let location = "EN";

const cookies = Cookies.get("rds_client_webgl")
  ? JSON.parse(Cookies.get("rds_client_webgl"))
  : null;

if (cookies && cookies.storeLang && cookies.storeLang.currentLocale) {
  location = cookies.storeLang.currentLocale;
}

const i18n = createI18n({
  locale: location,
  fallbackLocale: location,
  messages
});

export default i18n;
