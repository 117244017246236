import { createStore } from "vuex";
import auth from "../store/auth";
import { LOADING } from "../utils/constant";
import thongke from "../store/thongke"
import SignInApi from "../store/SignInApi"
import lang from "../store/lang"
export default createStore({
  modules: {
    thongke,
    auth,
    SignInApi,
    lang
  },
  plugins: [
    // createPersistedState({
    //   key: "rds_client_webgl",
    //   paths: ["storeLang.currentLocale"],
    //   storage: {
    //     getItem: (key) => Cookies.get(key),
    //     setItem: (key, value) =>
    //       Cookies.set(key, value, {
    //         expires: 120 / 1440,
    //         secure: false,
    //       }),
    //     removeItem: (key) => Cookies.remove(key),
    //   },
    // }),
  ],
  state() {
    return {
      showLoading: false,
    };
  },
  mutations: {
    [LOADING](state, payload) {
      state.showLoading = payload;
    },
  },
});
