import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { required, email, min, url } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import firebase from "firebase";
import enMessage from "../lang/en.json";
import jpMessage from "@/lang/jp.json";

export default app => {
  defineRule("required", required);
  defineRule("email", email);
  defineRule("min", min);
  defineRule("url", url);
  defineRule("unique", async (value, args) => {
    let collection, field, excluding;
    if (Array.isArray(args)) {
      [collection, field, excluding] = args;
    } else {
      ({ collection, field, excluding } = args);
    }
    if (value === excluding) return true;
    const querySnapshot = await firebase
      .firestore()
      .collection(collection)
      .where(field, "==", value)
      .get();
    return querySnapshot.empty;
  });

  configure({
    generateMessage: localize({
      en: enMessage,
      jp: jpMessage,
    }),
  });

  app.component("VeeForm", Form);
  app.component("VeeField", Field);
  app.component("VeeErrorMessage", ErrorMessage);
};
