<template>
  <div class="pad">
    <div class="header-control row">
      <div class="col-7 header-control-left">
        <div class="boxTT">
          <div class="boxTT_img">
            <div>
              <img src="../../assets/images/iconDoanhThuBox.svg" alt="">
            </div>
          </div>
          <div class="boxTT_text">
            <lable class="boxTT_Tiltle">Tồn</lable>
            <lable class="boxTT_Total">{{ parseFloat(total).toLocaleString() }}</lable>
          </div>
        </div>
        <div class="boxTT">
          <div class="boxTT_img">
            <div>
              <img src="../../assets/images/iconDoanhThuBox.svg" alt="">
            </div>
          </div>
          <div class="boxTT_text">
            <lable class="boxTT_Tiltle">Tổng thu</lable>
            <lable class="boxTT_Total">{{ parseFloat(tongThu).toLocaleString() }}</lable>
          </div>
        </div>
        <div class="boxTT">
          <div class="boxTT_img">
            <div>
              <img src="../../assets/images/iconDoanhThuBox.svg" alt="">
            </div>
          </div>
          <div class="boxTT_text">
            <lable class="boxTT_Tiltle">Tổng chi</lable>
            <lable class="boxTT_Total">{{ parseFloat(tongChi).toLocaleString() }}</lable>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="header-control-rigth">
          <el-form label-position="left" label-width="120px">
            <el-form-item class="el-lable" label="Thời gian">
              <el-date-picker v-model="valueNgay" class="dateThongke" :clearable="false" type="date" format="DD/MM/YYYY"
                @change="handleChangDate()" />
              <span class="spaceCharacter">-</span>
              <el-date-picker v-model="valueNgayDen" class="dateThongke" :clearable="false" type="date" format="DD/MM/YYYY"
                @change="handleChangDate()" />
            </el-form-item>
            <el-form-item label="Kho">
              <el-select @change="handleChangKho()" v-model="paramTongThu.maKho" placeholder="Tất cả">
                <el-option v-for="item in itemKho" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </div>
    <div class="content-wrapper">
      <div class="table-row-download">
        <img src="../../assets/images/btnSave.png" @click="exportToExcel()" alt="">
      </div>
      <div class="table-container">
          <table class="table table-hover">

            <thead>
              <tr class="background">
                <th class="center-header-left">
                  <span class="fontsize_13 columnSTT">STT</span>
                </th>

                <th class="left-header">

                  <span class="fontsize_13 column18">Ngày</span>
                </th>
                <th class="left-header">

                  <span class="fontsize_13 column18">Số hóa đơn</span>
                </th>
                <th class="left-header">
                  <span class="fontsize_13 column18">Tiền thu</span>
                </th>
                <th class="left-header">
                  <span class="fontsize_13 column18">Tiền hoàn trả</span>
                </th>
                <th class="left-header">
                  <span class="fontsize_13 column18">Tiền nhập</span>
                </th>
                <th class="left-header">
                  <span class="fontsize_13 columnAuto">Tồn</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(resource, index) in itemTable" :key="resource._id">
                <td class="text-center">{{ resource.rnum }}</td>
                <td class="text-left">{{ resource.ngay }}</td>
                <td class="text-left">{{ resource.iD_PHIEU_KHAM }}</td>
                <td class="text-left">{{ parseFloat(resource.thanH_TIEN_NT).toLocaleString() }}</td>
                <td class="text-left">{{ parseFloat(resource.thanH_TIEN_HT).toLocaleString() }}</td>
                <td class="text-left">
                  {{ parseFloat(resource.tonG_THANH_TIEN_NHAP_XUAT_KHO).toLocaleString() }}
                </td>
                <td class="text-left">
                  {{ parseFloat(resource.tonG_NGAY).toLocaleString() }}
                </td>
              </tr>
              <tr v-if="itemTable.length == 0">
                <td colspan="7" class="text-center">
                  Không có dữ liệu
                </td>
              </tr>
            </tbody>

          </table>
        </div>
        <div class="footer-bgwhite">
          <pageTT :totalItem="totalItem" @pagechanged="changePage" v-on:perpagechanged="perPageSTT"></pageTT>
        </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapActions } from "vuex";
import PageTT from "../../components/PageTT.vue";
import Moment from 'moment';
import { LOADING } from "../../utils/constant";
import { ExcelMixin } from "../../mixins/exportExcel"
export default {
  components: { PageTT },
  mixins: [ExcelMixin],
  data() {
    return {
      addResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      editResourceForm: {
        title: "",
        path: "",
        activated: 0,
      },
      idItemDelete: "",
      itemTable: [],
      msg: "",
      total: 0,
      tongThu: 0,
      tongChi: 0,
      totalPages: 0,
      showPopup: false,
      selectedUser: null,
      itemResource: [],
      perPage: 10,
      applyFilters: [], // Dữ liệu ban đầu
      searchDonVi: '', // Giá trị tìm kiếm cho cột "Đơn vị tiến hành"
      searchSoQuyetDinh: '', // Giá trị tìm kiếm cho cột "Số quyết định"
      searchNgayBanHanh: '', // Giá trị tìm kiếm cho cột "Ngày ban hành"
      searchNoiDung: '', // Giá trị tìm kiếm cho cột "Nội dung quyết định"
      searchDoiTuong: '', // Giá trị tìm kiếm cho cột "Đối tượng"
      searchTrangThai: '', // Giá trị tìm kiếm cho cột "Trạng Thái"
      totalItem: null,
      paramTongThu: {
        tuNgay: Moment(new Date()).format('DD/MM/YYYY'),
        denNgay: Moment(new Date()).format('DD/MM/YYYY'),
        maKho: "-1",
        index: 1,
        pagesize: 10
      },
      valueNgay: new Date(),
      valueNgayDen: new Date(),
      itemKho: []
    };
  },

  created() {
    this.get();
    this.getItemKho();
  },

  methods: {
    ...mapActions('thongke', ['GetDoanhThuNhaThuoc', 'getKho', 'GetDoanhThuNhaThuocExport']),
    ...mapMutations({
      showLoading: LOADING,
    }),
    formatNumber(numberIn) {
      const absoluteNumber = Math.abs(numberIn);
      let formattedNumber = '';
      if (absoluteNumber >= 1000000000) {
        formattedNumber = (absoluteNumber / 1000000000).toFixed(2) + 'B';
      } else if (absoluteNumber >= 1000000) {
        formattedNumber = (absoluteNumber / 1000000).toFixed(2) + 'M';
      } else if (absoluteNumber >= 1000) {
        formattedNumber = (absoluteNumber / 1000).toFixed(0) + 'K';
      } else {
        formattedNumber = absoluteNumber.toString();
      }
      return (numberIn < 0 ? '-' : '') + formattedNumber;
    },
    exportToExcel() {
      const header = ['STT', 'Ngày', 'Số hóa đơn', 'Tiền thu', 'Tiền hoàn trả', 'Tiền nhập', 'Tồn'];
      const filename = 'DoanhThuNhaThuoc_' + Moment().format('DDMMYYYYHHmmss') + '.xlsx';
      let dataEx = [];
      this.showLoading(true);
      this.GetDoanhThuNhaThuocExport(this.paramTongThu).then((res) => {
        dataEx = res.item.lstItem;
      }).finally(()=>{
        this.showLoading(false);
      this.fillDataToExcel( dataEx, header, filename);
      })
    },
    get() {
      this.showLoading(true);
      this.GetDoanhThuNhaThuoc(this.paramTongThu).then((res) => {
        this.itemTable = res.item.lstItem
        this.totalItem = res.item.totalRecords;
        this.total = res.item.tong_ngay;
        this.tongThu = res.item.tong_tien_nt;
        this.tongChi = parseFloat(res.item.tong_nxk) + parseFloat(res.item.tong_tien_ht);
      }).finally(this.showLoading(false));
    },
    getItemKho() {
      this.getKho().then((res) => {
        this.itemKho = res.items
      });
    },
    changePage(page) {
      this.paramTongThu.index = page;
      this.get();
    },
    perPageSTT(perPage) {
      this.paramTongThu.pagesize = perPage;
      this.paramTongThu.index = 1;
      this.get();
    },
    openPopup() {
      this.isOpen = true;
    },
    gotoPage(page) {
      this.paramTongThu.index = page;
    },
    nextPage() {
      if (this.paramTongThu.index < this.totalPages) {
        this.paramTongThu.index++;
      }
    },
    handlePageChange(event) {
      const selectedPage = event.page; // Lấy số trang được chọn
      this.paramTongThu.index = selectedPage;
    },
    handleValueChange(event) {
      const selectedRowsPerPage = event.value;
      this.paramTongThu.pagesize = selectedRowsPerPage;
      this.paramTongThu.index = 1;
    },

    previousPage() {
      if (this.paramTongThu.index > 1) {
        this.paramTongThu.index--;
      }
    },
    openPopup(user) {
      this.showPopup = true;
      this.selectedUser = user;
    },
    closePopup() {
      this.showPopup = false;
      this.selectedUser = null;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${day}-${month}-${year}`;
    },
    handleChangKho(){
      this.paramTongThu.index = 1;
      this.get();
    },
    handleChangDate() {
      if (this.valueNgay > this.valueNgayDen) {
        ElNotification.error({
          title: 'Cảnh báo',
          message: 'Từ ngày không được lớn hơn đến ngày',
          offset: 50,
          duration: 2500
        })
      }
      this.paramTongThu.index = 1;
      this.paramTongThu.tuNgay = Moment(this.valueNgay).format('DDMMYYYY');
      this.paramTongThu.denNgay = Moment(this.valueNgayDen).format('DDMMYYYY');
      this.get();
    },
  },
  computed: {
  }
};
</script>
  
<style scoped>
.pad {
  padding: 8px 16px;
  height: 100vh;
  overflow-y: auto;
}


.form-perpage1 {
  color: black;
  font-weight: 400;
}

.form-perpage2 {
  color: black;
  font-weight: 300;
}

.content-wrapper {
  background-color: var(--base-background, #F5F8FA);
  ;
  /* padding: 20px; */
  border-radius: 10px;
  margin-top: 20px;
}

.status-cell {
  padding: 4px;
  border-radius: 4px;
}

.select-input {
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px;
  gap: 10px;
  border: 1px solid #727981;
}

.header-control {
  padding-top: 0;
  justify-content: space-between;
  align-items: center;
}

.header-control-left {
  display: flex;
}

.header-control-rigth {
  float: right;
}

.input-control {
  max-width: 222px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-bottom: 10px;
}

.el-select {
  width: 100%;
}
</style>
  