<template>
    <label class="formLabel" :for="name">{{label}}</label>
    <VeeField
      :name="name"
      :label="label"
      :modelValue="modelValue"
      @input="$emit('modelUpdate', $event)"
      :id="name"
      class="form-input form-control"
      v-bind="$attrs"
    />
    <VeeErrorMessage :name="name" class="form-error" />
</template>
<script>
export default {
  methods: {
  },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    modelValue: { type: String, default: '' }
  }
}
</script>
