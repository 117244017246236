<template>

</template>

<script>

export default {
  data() {
    return {
      userDropdownOpen: false,
      mobileNavMenu: false,
      username: "",
      localizationForm: [],
      currentLanguage: localStorage.getItem("x-Ricoh-Langcode")
    };
  },
  computed: {},
  methods: {
    SignOut() {
      // Swal.fire({
      //   title: this.$t("do_you_want_to_log_out"),
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#aaa",
      //   confirmButtonText: this.$t("yes"),
      //   cancelButtonText: this.$t("cancel")
      // }).then(result => {
      //   if (result.isConfirmed) {
      //     window.localStorage.removeItem("x-token-nhis");
      //     const redirectTo = this.$route.query.redirectTo || { name: "SignIn" };
      //     this.$router.push(redirectTo);
      //   }
      // });
    },
    async changeLanguage() {
      this.$store.dispatch("setLang", {
        vue: this,
        lang: this.currentLanguage
      });
      window.localStorage.setItem("x-Ricoh-Langcode", this.currentLanguage);
      window.location.reload();
    },
    async getLanguage() {
      await this.$store
        .dispatch("auth/getlanguagelogin", 2)
        .then(response => {
          this.localizationForm = response.item;
        })
        .catch(err => {});
    }
  },
  created() {
    this.username = window.localStorage.getItem("username");
    this.$router.beforeEach((to, from) => {
      this.mobileNavMenu = false;
    });
    this.getLanguage();
  }
};
</script>

<style scoped>
#header {
  background-image: url("../assets/images/bannerHeader.png");
  background-size: 110%;
}

.navbar-item {
  color: white;
  position: relative;
  display: flex;
}

.swal-button {
  width: 150px;
}

ul {
  list-style: none;
}

.swal2-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962b3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.logo {
  margin-left: 1%;
}
.banner {
  margin-left: 1%;
  margin-top: 0.5%;
}
.user_name {
  background: inherit;
  color: white;
  font-size: 16px;
}

.navbar ul li {
  margin-left: 25px;
}

.icon-logout {
  width: 18px;
  background: inherit;
  color: white;
}

.selected_language {
  background: inherit;
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
}
.selected_language option {
  background-color: rgb(78, 104, 182);
}
.navbar-item {
  cursor: pointer;
}
.langguage {
  cursor: pointer;
}
</style>
