import axios from "axios";
import { ElNotification } from 'element-plus';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api base_url // Url
  timeout: 600000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("x-token-nhis");
    if (token) {
      config.headers["x-token-nhis"] = `${token}`;
    }
    return config;
  },
  (error) => {

    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  function (response) {
    // eslint-disable-next-line no-unused-expressions
    // Bắt thêm case 201 , 409
    switch (response.data.errorCode) {
      case 200:
        return response;
      case 100:
          return response;
      case 201:
        return response;
      case 401:
          window.localStorage.removeItem("x-token-nhis");
      case 101:
          window.localStorage.removeItem("x-token-nhis");
          ElNotification.error({
            title: 'Lỗi',
            message: 'sai tài khoản hoặc mật khẩu',
            offset: 50,
          })
          window.location.href = "/";
      case 409:
          window.localStorage.removeItem("x-token-nhis");
      case 520:
        window.localStorage.removeItem("x-token-nhis");
      // case 409:
      //   console.log("409 - refresh Token");
      //   return response;
      default:
        return "";
    }
  },
  function (error) {
    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
// Xoá body , get truyền vào url
export default service;
export function get(url) {
  return new Promise((resolve, reject) => {
    service
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function post(url, data) {
  return new Promise((resolve, reject) => {
    service
      .post(url, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function put(url, data) {
  return new Promise((resolve, reject) => {
    service
      .put(url, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function del(url) {
  return new Promise((resolve, reject) => {
    service
      .delete(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
