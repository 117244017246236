import { post} from '../utils/request'
import {LOGIN_API} from '../api/signInApi'
const actions = {
  signIn(context,body ){
   return post(LOGIN_API.url, body).then(res => {
    return res;
   }).catch (e => {
    
   })
  },
};
export default {
  namespaced: true,
    actions
  }