/* eslint-disable vue/script-setup-uses-vars */
import { login, logout, getlanguagelogin } from '../api/auth'
const actions = {
  login ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      login(payload)
        .then(response => {
          if (response.status == 200) {
            window.localStorage.setItem('x-token-nhis', response.token),
            window.localStorage.setItem('username',this.username)
            window.localStorage.setItem('password', this.password)
           // window.localStorage.setItem('username', response.data.username)
          }
          resolve(response.data) // resovle
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  logout ({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout()
        .then((response) => {
          // removeToken() // remove token
          window.localStorage.removeItem('x-token-nhis')
          resolve('OK')
        })
        .catch(function () {
          // removeToken() // remove token
          window.localStorage.removeItem('x-token-nhis')
          resolve('OK')
        })
    })
  },
  getlanguagelogin ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      getlanguagelogin(payload)
        .then(response => {
          resolve(response.data) // resovle
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
}

const state = () => ({})
const getters = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
